import React from "react";
import { withTranslation } from "react-i18next";
import { Button, Nav, NavItem, NavLink } from "reactstrap";
import {
  GET_CODE_BY_CODE_CATEGORY,
  GET_LICENCE_INBOX_ALL_SERVER_SIDE_URL,
  GET_LICENCE_INBOX_COUNT_URL,
  GET_LICENCE_INBOX_RENEWAL_ALL_SERVER_SIDE_URL,
  IS_DEMO,
  LICENCE_ENQUIRY_PARAMS_URL,
} from "../../App/AppSettings";
import { fetchRequest, getParams, navigateTo } from "../../Shared/Actions";
import { DIRECTIONS, FILTER, MIN_FILTER_CHARS } from "../../Shared/Constants";
import { ERROR, LANGUAGE_KEYS } from "../../Shared/Constants/LanguageKeys";
import { ServerSideDataTable } from "../../Shared/DataTable";
import { getCustomComponents } from "../../Shared/DataTable/BaseTable.js";
import DropdownButton from "../../Shared/DropdownButton/DropdownButton.js";
import { SelectList, SMARTForm, Text, toastError } from "../../Shared/Forms";
import {
  arrayToSelectListOptions,
  arrayToTranslatedLicenceNameSelectListOptions,
  arrayToTranslatedLicenceStatusSelectListOptions,
} from "../../Shared/Forms/FormUtils.js";
import { withLoader } from "../../Shared/Loading";
import {
  getLangKey,
  getLicenceTypeNameTranslationKey,
} from "../DisplayComponents/DisplayUtils";

const FORM_CONTEXT = "Licences";
const LABEL_SIZE = 0;
const INPUT_SIZE = 12;

//data table server side url
const ALL_INBOX_URL = GET_LICENCE_INBOX_ALL_SERVER_SIDE_URL;
const RENEW_INBOX_URL = GET_LICENCE_INBOX_RENEWAL_ALL_SERVER_SIDE_URL;
const INBOX_COUNT_URL = GET_LICENCE_INBOX_COUNT_URL;

const PARAMS_URL = LICENCE_ENQUIRY_PARAMS_URL;

const ACTIONS = {
  RENEW: "Renew",
  AMEND: "Amend",
  CANCEL: "Cancel",
};

const FORM_INITIAL_VALUES = {
  LicenceName: "",
  LicenceNumber: "",
  AgencyName: "",
  LicenceStatus: "",
};

const tabs = {
  DUE_FOR_RENEWAL: "DUE_FOR_RENEWAL",
  ALL: "ALL",
};

const INBOX_FILTER = {
  SHOW_RENEWAL: tabs.DUE_FOR_RENEWAL,
  SHOW_ALL: tabs.ALL,
};

class Licences extends React.Component {
  constructor(props) {
    super(props);
    const { t } = this.props;
    this.state = {
      activeTab: tabs.DUE_FOR_RENEWAL,
      values: FORM_INITIAL_VALUES,
      success: "",
      data: "",
      count: "",
      licenceNameOptions: [],
      agencyNameOptions: [],
      licenceStatusOptions: [],
      showAdvancedSearch: false,
      licenceAllCount: undefined,
      tabNames: {
        DUE_FOR_RENEWAL: t(
          LANGUAGE_KEYS.BLS_PUBLICWORKSPACELICENCE_CONTENT_MENU_DUEFORRENEWAL
        ),
        ALL: t(LANGUAGE_KEYS.BLS_PUBLICWORKSPACELICENCE_CONTENT_MENU_ALL),
      },
    };
  }

  componentDidMount = async () => {
    const { loader } = this.props;
    //start loading
    loader.start();

    const countData = await this.getCountData(INBOX_COUNT_URL);
    const actionDropdowns = await this.getActionItems();

    this.setState({
      searchAllURL: ALL_INBOX_URL,
      searchRenewURL: RENEW_INBOX_URL,
      triggerRenewTable: Math.random(),
      extraGetParams: {},
      licenceAllCount: countData.licenceAllCount,
      actionDropdowns,
    });

    loader.done();
  };

  getActionItems = async () => {
    const CODE_URL = `${GET_CODE_BY_CODE_CATEGORY}?categories=LicenceApplicationType`;
    const response = await fetchRequest(CODE_URL, getParams());
    const { IsSuccess, Data } = response.body;
    let amendCodeId, renewCodeId, cancelCodeId;
    if (response.success && IsSuccess) {
      const amendCode = Data.find(
        (a) => a.CodeTitle.toLowerCase() === ACTIONS.AMEND.toLowerCase()
      );
      const renewCode = Data.find(
        (a) => a.CodeTitle.toLowerCase() === ACTIONS.RENEW.toLowerCase()
      );
      const cancelCode = Data.find(
        (a) => a.CodeTitle.toLowerCase() === ACTIONS.CANCEL.toLowerCase()
      );

      amendCodeId = amendCode ? amendCode.CodeName : null;
      renewCodeId = renewCode ? renewCode.CodeName : null;
      cancelCodeId = cancelCode ? cancelCode.CodeName : null;
    }

    return { amendCodeId, renewCodeId, cancelCodeId };
  };

  setLicenseRenewCount = (response) => {
    const { TotalRecordCount } = response.body;
    this.setState({ licenceRenewCount: TotalRecordCount });
  };

  getCountData = async (url) => {
    const { t } = this.props;
    const response = await fetchRequest(url, getParams(), IS_DEMO);
    const { IsSuccess, LicenceAllCount } = response.body;

    // If success, load retrieved data into state
    if (IsSuccess) {
      return {
        licenceAllCount: LicenceAllCount,
      };
    } else {
      toastError(t(ERROR.LIST_RETRIEVAL_FAIL));

      return {
        licenceAllCount: 0,
      };
    }
  };

  setContent = (rowData) => {
    return (
      <div
        className="dashboard-licences-link"
        onClick={() => this.redirectToRenewLicencePage(rowData)}
      >
        {rowData.LicenceNumber}
      </div>
    );
  };

  setMobileContent = (rowData) => {
    const { t } = this.props;
    return (
      <tr key={"LicenceNumber"}>
        <td width="40%" className="card-table-td card-table-title">
          <b>
            {t(
              LANGUAGE_KEYS.BLS_PUBLICWORKSPACELICENCE_TABLE_TITLE_SERVICENUMBER
            )}
          </b>
          :
        </td>
        <td width="60%" className="card-table-td card-table-data">
          {this.setContent(rowData)}
        </td>
      </tr>
    );
  };

  setLicenceNameTranslatedContent = (rowData) => {
    const { t } = this.props;
    const licenceTypeNameTranslationKey = getLicenceTypeNameTranslationKey(
      rowData.LicenceName
    );
    return (
      <>
        {t(
          getLangKey(
            LANGUAGE_KEYS.BLS_SCOPE_LICENCETYPE_LICENCETYPENAME_KEY,
            licenceTypeNameTranslationKey,
            rowData.LicenceTypeId
          )
        )}
      </>
    );
  };

  setLicenceStatusTranslatedContent = (rowData) => {
    const { t } = this.props;
    return (
      <>
        {t(
          getLangKey(
            LANGUAGE_KEYS.BLS_CODE_LICENCESTATUS_TITLE_KEY,
            rowData.LicenceStatusCodeName
          )
        )}
      </>
    );
  };

  handleSelectChange = (key, rowData) => {
    if (key === ACTIONS.RENEW) {
      return this.redirectToRenewLicencePage(rowData);
    }
    if (key === ACTIONS.AMEND) {
      return this.redirectToAmendLicencePage(rowData);
    }
    if (key === ACTIONS.CANCEL) {
      return this.redirectToCancelLicencePage(rowData);
    }
  };

  redirectToRenewLicencePage = (rowData) => {
    navigateTo("/serviceDetails", { licence: rowData });
  };

  redirectToAmendLicencePage = (rowData) => {
    navigateTo("/services/devform/start", {
      applicationType: "AMEND",
      LicenceId: rowData.LicenceID,
    });
  };

  redirectToCancelLicencePage = (rowData) => {
    navigateTo("/services/devform/start", {
      applicationType: "CANCEL",
      LicenceId: rowData.LicenceID,
    });
  };

  getCustomComponents = () => {
    let components = getCustomComponents();
    components.Filter = this.Filter;

    return components;
  };

  onFilterChange = (filterName, value, setFilter) => {
    this.setState({
      [filterName]: value,
    });
    const filterFields = {
      [FILTER]: value,
    };
    setFilter(filterFields);
  };

  Filter = ({ setFilter }) => {
    const { t } = this.props;
    return (
      <div
        className={`${
          this.state.showAdvancedSearch
            ? "dashboard-search search-hidden"
            : "dashboard-search"
        }`}
      >
        <div className="search">
          <div className="search-box">
            <div className="search-cell search-head">
              {this.state.tabNames[this.state.activeTab]}
            </div>
            <div className="search-cell">
              <div className="input-group">
                <input
                  dir={DIRECTIONS.AUTO}
                  type="text"
                  name="filter"
                  defaultValue={this.state.filter}
                  className="form-control"
                  placeholder={t(
                    LANGUAGE_KEYS.BLS_PUBLICWORKSPACELICENCE_CONTENT_PLACEHOLDER_SERVICESEARCHBOX
                  )}
                  onChange={(e) =>
                    this.onFilterChange("filter", e.target.value, setFilter)
                  }
                />
                <div className="input-group-append">
                  <button
                    className="btn btn-outline-secondary"
                    type="button"
                    onClick={(e) =>
                      this.onFilterChange(
                        "filter",
                        this.state.filter,
                        setFilter
                      )
                    }
                  >
                    <i className="fal fa-search"></i>
                  </button>
                </div>
              </div>
            </div>
            <div className="search-cell advanced-search">
              <span onClick={this.toggleAdvancedSearch}>
                {t(
                  LANGUAGE_KEYS.BLS_PUBLICWORKSPACELICENCE_CONTENT_TITLE_ADVANCEDSEARCH
                )}
              </span>
            </div>
          </div>
        </div>
      </div>
    );
  };

  toggleAdvancedSearch = () => {
    const { activeTab } = this.state;
    //if showAdvancedSearch is previously false, it is going to be true so getLicenceFilterParams
    if (!this.state.showAdvancedSearch) {
      this.getLicenceFilterParams();
    }

    if (activeTab === tabs.DUE_FOR_RENEWAL) {
      this.setState((prevState) => {
        return {
          extraGetParams: {},
          triggerRenewTable: Math.random(),
          showAdvancedSearch: !prevState.showAdvancedSearch,
        };
      });
    } else if (activeTab === tabs.ALL) {
      this.setState((prevState) => {
        return {
          extraGetParams: {},
          triggerAllTable: Math.random(),
          showAdvancedSearch: !prevState.showAdvancedSearch,
        };
      });
    }
  };

  toggleTab = (tab) => {
    this.setState({
      activeTab: tab,
    });
  };

  getLicenceFilterParams = async () => {
    var inboxTab =
      this.state.activeTab === tabs.DUE_FOR_RENEWAL
        ? INBOX_FILTER.SHOW_RENEWAL
        : INBOX_FILTER.SHOW_ALL;
    const { t } = this.props;
    const response = await fetchRequest(
      PARAMS_URL + inboxTab,
      getParams(),
      IS_DEMO
    );
    const { IsSuccess, Data, Messages } = response.body;

    if (IsSuccess) {
      const { FormName, AgencyName, Status } = Data;
      let licenceNameOptions = [];
      let agencyNameOptions = [];
      let licenceStatusOptions = [];

      // Format filter params into SelectList format
      arrayToTranslatedLicenceNameSelectListOptions(
        FormName,
        licenceNameOptions,
        this.props.t
      );
      arrayToSelectListOptions(AgencyName, agencyNameOptions);
      arrayToTranslatedLicenceStatusSelectListOptions(
        Status,
        licenceStatusOptions,
        this.props.t
      );

      this.setState({
        licenceNameOptions: licenceNameOptions,
        agencyNameOptions: agencyNameOptions,
        licenceStatusOptions: licenceStatusOptions,
      });
    } else {
      toastError(
        t(getLangKey(ERROR.BACKEND_ERROR_MESSAGE, Messages)),
        Messages
      );
    }
  };

  getMenuItems = (RowData) => {
    const menuItems = [];
    const { t } = this.props;
    const { renewCodeId, amendCodeId, cancelCodeId } =
      this.state.actionDropdowns;

    if (RowData.IsRenewable) {
      menuItems.push({
        label: renewCodeId
          ? t(
              getLangKey(
                LANGUAGE_KEYS.BLS_CODE_LICENCEAPPLICATIONTYPE_TITLE_KEY,
                renewCodeId
              )
            )
          : ACTIONS.RENEW,
        value: ACTIONS.RENEW,
      });
    }
    if (RowData.IsAmendable) {
      menuItems.push({
        label: amendCodeId
          ? t(
              getLangKey(
                LANGUAGE_KEYS.BLS_CODE_LICENCEAPPLICATIONTYPE_TITLE_KEY,
                amendCodeId
              )
            )
          : ACTIONS.AMEND,
        value: ACTIONS.AMEND,
      });
    }
    if (RowData.IsCancellable) {
      menuItems.push({
        label: cancelCodeId
          ? t(
              getLangKey(
                LANGUAGE_KEYS.BLS_CODE_LICENCEAPPLICATIONTYPE_TITLE_KEY,
                cancelCodeId
              )
            )
          : ACTIONS.CANCEL,
        value: ACTIONS.CANCEL,
      });
    }
    return menuItems;
  };

  getRenewalMenuItems = (RowData) => {
    const menuItems = [];
    const { t } = this.props;
    const { renewCodeId, amendCodeId, cancelCodeId } =
      this.state.actionDropdowns;

    menuItems.push({
      label: renewCodeId
        ? t(
            getLangKey(
              LANGUAGE_KEYS.BLS_CODE_LICENCEAPPLICATIONTYPE_TITLE_KEY,
              renewCodeId
            )
          )
        : ACTIONS.RENEW,
      value: ACTIONS.RENEW,
    });

    if (RowData.IsAmendable) {
      menuItems.push({
        label: amendCodeId
          ? t(
              getLangKey(
                LANGUAGE_KEYS.BLS_CODE_LICENCEAPPLICATIONTYPE_TITLE_KEY,
                amendCodeId
              )
            )
          : ACTIONS.AMEND,
        value: ACTIONS.AMEND,
      });
    }
    if (RowData.IsCancellable) {
      menuItems.push({
        label: cancelCodeId
          ? t(
              getLangKey(
                LANGUAGE_KEYS.BLS_CODE_LICENCEAPPLICATIONTYPE_TITLE_KEY,
                cancelCodeId
              )
            )
          : ACTIONS.CANCEL,
        value: ACTIONS.CANCEL,
      });
    }
    return menuItems;
  };

  searchLicenceInbox = (values) => {
    const { activeTab } = this.state;

    if (activeTab === tabs.DUE_FOR_RENEWAL) {
      this.setState({
        extraGetParams: values,
        triggerRenewTable: Math.random(),
      });
    } else if (activeTab === tabs.ALL) {
      this.setState({
        extraGetParams: values,
        triggerAllTable: Math.random(),
      });
    }
  };

  renderMobileCardActions = (RowData, tabView) => {
    return this.setActionOptions(RowData, tabView);
  };

  setActionOptions = (RowData, tabView) => {
    switch (tabView) {
      case tabs.ALL:
        return this.allTab(RowData);

      case tabs.DUE_FOR_RENEWAL:
        return this.dueForRenewalTab(RowData);

      default:
        return this.allTab(RowData);
    }
  };

  allTab = (RowData) => {
    return (
      <DropdownButton
        className={"btn-action"}
        buttonLabel="Actions"
        disabled={this.getMenuItems(RowData).length > 0 ? false : true}
        menuItems={this.getMenuItems(RowData)}
        onChange={(e) => this.handleSelectChange(e.key, RowData)}
        placement="bottomRight"
      />
    );
  };

  dueForRenewalTab = (RowData) => {
    return (
      <DropdownButton
        className="btn-action"
        buttonLabel="Actions"
        menuItems={this.getRenewalMenuItems(RowData)}
        onChange={(e) => this.handleSelectChange(e.key, RowData)}
        placement="bottomRight"
      />
    );
  };

  render() {
    const {
      activeTab,
      showAdvancedSearch,
      searchRenewURL,
      searchAllURL,
      extraGetParams,
      licenceAllCount,
      licenceRenewCount,
    } = this.state;
    const { t } = this.props;

    return (
      <div id="Licences">
        <div className="inner-tabs">
          <Nav tabs className="tab-nav-tabs">
            <NavItem key={0} className="tab-nav-item">
              <NavLink
                className={"tab-header tab-nav-link"}
                active={activeTab === tabs.DUE_FOR_RENEWAL}
                id="Due_for_Renewal-tab"
                onClick={() => this.toggleTab(tabs.DUE_FOR_RENEWAL)}
              >
                {this.state.tabNames.DUE_FOR_RENEWAL}
                <span>{licenceRenewCount}</span>
              </NavLink>
            </NavItem>
            <NavItem key={1} className="tab-nav-item">
              <NavLink
                className={"tab-header tab-nav-link"}
                active={activeTab === tabs.ALL}
                id="Licences_All-tab"
                onClick={() => this.toggleTab(tabs.ALL)}
              >
                {this.state.tabNames.ALL}
                <span>{licenceAllCount}</span>
              </NavLink>
            </NavItem>
          </Nav>

          {showAdvancedSearch && (
            <SMARTForm
              formContext={FORM_CONTEXT}
              formValues={this.state.values}
              isDummy={IS_DEMO}
            >
              {({ values, errors, onChange, onChangeField }) => (
                <div className="dashboard-search">
                  <div
                    className={`${
                      showAdvancedSearch
                        ? "advanced-search-container search-shown"
                        : "advanced-search-container search-hidden"
                    }`}
                  >
                    <div className="advanced-serach-header">
                      {t(
                        LANGUAGE_KEYS.BLS_PUBLICWORKSPACELICENCE_CONTENT_TITLE_ADVANCEDSEARCH
                      )}
                      <span className="advanced-search-close">
                        <i
                          className="fal fa-times"
                          onClick={this.toggleAdvancedSearch}
                        ></i>
                      </span>
                    </div>
                    <div className="advanced-search-fields">
                      <div className="advanced-search-cell ">
                        <SelectList
                          name="LicenceTypeId"
                          value={values.LicenceTypeId}
                          options={this.state.licenceNameOptions}
                          onChangeField={onChangeField}
                          placeholder={t(
                            LANGUAGE_KEYS.BLS_PUBLICWORKSPACELICENCE_TABLE_TITLE_SERVICENAME
                          )}
                          isMulti={false}
                          isClearable={true}
                          inputSize={INPUT_SIZE}
                          labelSize={LABEL_SIZE}
                          error={errors.LicenceTypeId}
                        />
                      </div>
                      <div className="advanced-search-cell ">
                        <Text
                          name="LicenceNumber"
                          value={values.LicenceNumber}
                          placeholder={t(
                            LANGUAGE_KEYS.BLS_PUBLICWORKSPACELICENCE_TABLE_TITLE_SERVICENUMBER
                          )}
                          onChange={onChange}
                          minLength={0}
                          maxLength={140}
                          inputSize={INPUT_SIZE}
                          labelSize={LABEL_SIZE}
                          error={errors.LicenceNumber}
                        />
                      </div>
                      <div className="advanced-search-cell ">
                        <SelectList
                          name="AgencyName"
                          value={values.AgencyName}
                          options={this.state.agencyNameOptions}
                          onChangeField={onChangeField}
                          placeholder={t(
                            LANGUAGE_KEYS.BLS_PUBLICWORKSPACELICENCE_CONTENT_TITLE_AGENCYNAME
                          )}
                          isMulti={false}
                          isClearable={true}
                          inputSize={INPUT_SIZE}
                          labelSize={LABEL_SIZE}
                          error={errors.AgencyName}
                        />
                      </div>
                      <div className="advanced-search-cell ">
                        <SelectList
                          name="LicenceStatus"
                          value={values.LicenceStatus}
                          options={this.state.licenceStatusOptions}
                          onChangeField={onChangeField}
                          placeholder={t(
                            LANGUAGE_KEYS.BLS_PUBLICWORKSPACELICENCE_TABLE_TITLE_SERVICESTATUS
                          )}
                          isMulti={false}
                          isClearable={true}
                          inputSize={INPUT_SIZE}
                          labelSize={LABEL_SIZE}
                          error={errors.LicenceStatus}
                        />
                      </div>
                    </div>
                    <div className="button-center">
                      <Button
                        color="forward"
                        size="sm"
                        type="button"
                        onClick={() => this.searchLicenceInbox(values)}
                      >
                        {t(
                          LANGUAGE_KEYS.BLS_PUBLICWORKSPACELICENCE_CONTENT_BUTTON_SEARCH
                        )}
                      </Button>
                    </div>
                  </div>
                </div>
              )}
            </SMARTForm>
          )}

          {activeTab === tabs.DUE_FOR_RENEWAL && extraGetParams && (
            <ServerSideDataTable
              getGridDataResponse={this.setLicenseRenewCount}
              pageSize="5"
              minFilterChars={MIN_FILTER_CHARS}
              extraGetParams={extraGetParams}
              url={searchRenewURL}
              noResultsMessage={t(
                LANGUAGE_KEYS.BLS_PUBLICWORKSPACELICENCE_MESSAGE_NOTE_NOSERVICEFOUND
              )}
              isCustomFilter={true}
              key={this.state.triggerRenewTable}
              components={this.getCustomComponents()}
              columns={{
                LicenceNumber: {
                  title: t(
                    LANGUAGE_KEYS.BLS_PUBLICWORKSPACELICENCE_TABLE_TITLE_SERVICENUMBER
                  ),
                  width: "10%",
                  DBkey: "LicenceNumber",
                  setContent: this.setContent,
                  setMobileContent: this.setMobileContent,
                },
                LicenceName: {
                  title: t(
                    LANGUAGE_KEYS.BLS_PUBLICWORKSPACELICENCE_TABLE_TITLE_SERVICENAME
                  ),
                  width: "20%",
                  DBkey: "LicenceName",
                  setContent: this.setLicenceNameTranslatedContent,
                },
                LicenceStatus: {
                  title: t(
                    LANGUAGE_KEYS.BLS_PUBLICWORKSPACELICENCE_TABLE_TITLE_SERVICESTATUS
                  ),
                  width: "10%",
                  DBkey: "LicenceStatus",
                },
                Agency: {
                  title: t(
                    LANGUAGE_KEYS.BLS_PUBLICWORKSPACELICENCE_TABLE_TITLE_AGENCY
                  ),
                  width: "20%",
                  DBkey: "Agency",
                },
                StartDate: {
                  title: t(
                    LANGUAGE_KEYS.BLS_PUBLICWORKSPACELICENCE_TABLE_TITLE_STARTDATE
                  ),
                  isDate: true,
                  width: "10%",
                  DBkey: "StartDate",
                },
                ExpiryDate: {
                  title: t(
                    LANGUAGE_KEYS.BLS_PUBLICWORKSPACELICENCE_TABLE_TITLE_EXPIRYDATE
                  ),
                  isDate: true,
                  width: "10%",
                  DBkey: "ExpiryDate",
                },
                Actions: {
                  title: t(
                    LANGUAGE_KEYS.BLS_PUBLICWORKSPACELICENCE_TABLE_TITLE_ACTIONS
                  ),
                  width: "10%",
                  DBkey: "Actions",
                },
              }}
              renderActions={({ RowData }) =>
                this.setActionOptions(RowData, tabs.DUE_FOR_RENEWAL)
              }
              renderMobileCardActions={(RowData) =>
                this.renderMobileCardActions(RowData, tabs.DUE_FOR_RENEWAL)
              }
            />
          )}

          {activeTab === tabs.ALL && extraGetParams && (
            <ServerSideDataTable
              pageSize="5"
              minFilterChars={MIN_FILTER_CHARS}
              extraGetParams={extraGetParams}
              url={searchAllURL}
              noResultsMessage={t(
                LANGUAGE_KEYS.BLS_PUBLICWORKSPACELICENCE_MESSAGE_NOTE_NOSERVICEFOUND
              )}
              isCustomFilter={true}
              key={this.state.triggerAllTable}
              components={this.getCustomComponents()}
              columns={{
                LicenceNumber: {
                  title: t(
                    LANGUAGE_KEYS.BLS_PUBLICWORKSPACELICENCE_TABLE_TITLE_SERVICENUMBER
                  ),
                  width: "10%",
                  DBkey: "LicenceNumber",
                  setContent: this.setContent,
                  setMobileContent: this.setMobileContent,
                },
                LicenceName: {
                  title: t(
                    LANGUAGE_KEYS.BLS_PUBLICWORKSPACELICENCE_TABLE_TITLE_SERVICENAME
                  ),
                  width: "20%",
                  DBkey: "LicenceName",
                  setContent: this.setLicenceNameTranslatedContent,
                },
                LicenceStatus: {
                  title: t(
                    LANGUAGE_KEYS.BLS_PUBLICWORKSPACELICENCE_TABLE_TITLE_SERVICESTATUS
                  ),
                  width: "10%",
                  DBkey: "LicenceStatus",
                  setContent: this.setLicenceStatusTranslatedContent,
                },
                Agency: {
                  title: t(
                    LANGUAGE_KEYS.BLS_PUBLICWORKSPACELICENCE_TABLE_TITLE_AGENCY
                  ),
                  width: "20%",
                  DBkey: "Agency",
                },
                StartDate: {
                  title: t(
                    LANGUAGE_KEYS.BLS_PUBLICWORKSPACELICENCE_TABLE_TITLE_STARTDATE
                  ),
                  isDate: true,
                  width: "10%",
                  DBkey: "StartDate",
                },
                ExpiryDate: {
                  title: t(
                    LANGUAGE_KEYS.BLS_PUBLICWORKSPACELICENCE_TABLE_TITLE_EXPIRYDATE
                  ),
                  isDate: true,
                  width: "10%",
                  DBkey: "ExpiryDate",
                },
                Actions: {
                  title: t(
                    LANGUAGE_KEYS.BLS_PUBLICWORKSPACELICENCE_TABLE_TITLE_ACTIONS
                  ),
                  width: "10%",
                  DBkey: "Actions",
                },
              }}
              renderActions={({ RowData }) =>
                this.setActionOptions(RowData, tabs.ALL)
              }
              renderMobileCardActions={(RowData) =>
                this.renderMobileCardActions(RowData, tabs.ALL)
              }
              initialSort={{
                id: 'StartDate',
                sortAscending: false,
                isDate: true
            }}
            />
          )}
        </div>
      </div>
    );
  }
}

export default withTranslation()(withLoader(Licences));
