import React, { useState, useRef, useEffect } from "react";
import {
	GoogleMap,
	Marker,
	useJsApiLoader,
	Autocomplete,
	DrawingManager,
	Polygon,
	Circle,
} from "@react-google-maps/api";
import "../../../Css/formio.custom.css";

const containerStyle = {
	width: "100%",
	height: "400px",
};

const center = {
	lat: 23.57015118753728,
	lng: 58.379394740438556
};

const GoogleMapComponent = ({ value, onChange, isView }) => {
	const [address, setAddress] = useState("");
	const [mapOutput, setMapOutput] = useState({});

	const disableMapOptions = {
		draggable: false, // Disable dragging
		scrollwheel: false, // Disable zooming with the scroll wheel
		disableDefaultUI: true, // Disable default UI controls
		zoomControl: false, // Disable zoom control
	};

	const enableMapOptions = {
		draggable: true,
		scrollwheel: true,
		disableDefaultUI: false,
		zoomControl: true,
	};

	const [map, setMap] = useState(null);
	const [markerPosition, setMarkerPosition] = useState(center);
	const autocompleteRef = useRef(null);
	const [zoom, setZoom] = useState(14);

	const onPlaceChanged = () => {
		const place = autocompleteRef.current.getPlace();
		if (place && place.geometry) {
			const addressFull = place.address_components
				.map((item) => item.long_name)
				.join(", ");
			setAddress(addressFull);
			const { lat, lng } = place.geometry.location;
			const newCenter = {
				lat: lat(),
				lng: lng(),
			};
			setZoom(14);
			setMarkerPosition(newCenter);
			setMapOutput({
				...mapOutput,
				lat: newCenter.lat,
				lng: newCenter.lng,
				address: addressFull,
			});
			map.panTo(newCenter);
		}
	};

	const onLoad = (map) => {
		setMap(map);
	};

	function onDrawingPolygonCompleted(polygon) {
		let polygons = mapOutput.polygons ?? [];

		const polygonPath = polygon
			.getPath()
			.getArray()
			.map((latLng) => ({
				lat: latLng.lat(),
				lng: latLng.lng(),
			}));

		polygons = [...polygons, polygonPath];
		polygon.setMap(null);
		setMapOutput({ ...mapOutput, polygons });
	}

	function onDrawingCircleCompleted(circle) {
		let circles = mapOutput.circles ?? [];

		const circlePath = {
			center: circle.getCenter().toJSON(),
			radius: circle.getRadius(),
		};

		circles = [...circles, circlePath];
		circle.setMap(null);
		setMapOutput({ ...mapOutput, circles });
	}

	function onAddingMarkerCompleted(marker) {
		let markers = mapOutput.markers ?? [];

		const markerPath = marker.getPosition().toJSON();

		markers = [...markers, markerPath];
		marker.setMap(null);
		setMapOutput({ ...mapOutput, markers });
	}

	useEffect(() => {
		if (!isView) onChange(mapOutput);
	}, [mapOutput]);

	useEffect(() => {
		if (value && Object.keys(value).length > 0) {
			setAddress(value.address);

			//#region populate drawings
			let tempPolygons = mapOutput.polygons ?? [];
			let tempCircles = mapOutput.circles ?? [];
			let tempMarkers = mapOutput.markers ?? [];
			for (const inputKey of Object.keys(value)) {
				if (inputKey.includes("polygons")) {
					const match = inputKey.match(
						/polygons\.(\d+)\.(\d+)\.(lat|lng)/
					);
					if (match) {
						const [, polygonIndex, pointIndex, coordType] = match;
						if (!tempPolygons[polygonIndex]) {
							tempPolygons = [...tempPolygons, []];
						}

						if (!tempPolygons[polygonIndex][pointIndex]) {
							tempPolygons[polygonIndex][pointIndex] = {};
						}

						tempPolygons[polygonIndex][pointIndex][coordType] =
							parseFloat(value[inputKey]);
					}
				}

				if (inputKey.includes("markers")) {
					const match = inputKey.match(/markers\.(\d+)\.(lat|lng)/);
					if (match) {
						const [, markerIndex, coordType] = match;
						if (!tempMarkers[markerIndex]) {
							tempMarkers[markerIndex] = {};
						}

						tempMarkers[markerIndex][coordType] = parseFloat(
							value[inputKey]
						);
					}
				}

				if (inputKey.includes("circles")) {
					const match = inputKey.match(
						/circles\.(\d+)\.center\.(lat|lng)|circles\.(\d+)\.radius/
					);
					if (match) {
						const circleIndex = match[1] || match[3];
						const coordType = match[2];

						if (!tempCircles[circleIndex]) {
							tempCircles[circleIndex] = { center: {} };
						}

						if (coordType) {
							tempCircles[circleIndex].center[coordType] =
								parseFloat(value[inputKey]);
						} else {
							tempCircles[circleIndex].radius = parseFloat(
								value[inputKey]
							);
						}
					}
				}
			}
			const newMapOutput = {
				address: value.address,
				lat: value.lat,
				lng: value.lng,
				polygons: tempPolygons,
				circles: tempCircles,
				markers: tempMarkers,
			};
			//#endregion

			setMapOutput(newMapOutput);

			setMarkerPosition({
				lat: parseFloat(value.lat),
				lng: parseFloat(value.lng),
			});
		}
	}, []);

	function clearDrawingOnClick() {
		setMapOutput({ ...mapOutput, polygons: [], circles: [], markers: [] });
	}

	const { isLoaded } = useJsApiLoader({
		id: "google-map-script",
		googleMapsApiKey: "AIzaSyAU583GE9E8p0gkshu6vavl7J3beUhDp20",
		libraries: ["places", "drawing"],
	});

	return (
		<div>
			{isLoaded && (
				<div>
					<Autocomplete
						onLoad={(autocomplete) =>
							(autocompleteRef.current = autocomplete)
						}
						onPlaceChanged={onPlaceChanged}
					>
						<input
							type="text"
							placeholder="Search for a location"
							className="form-control"
							value={address}
							onChange={(e) => setAddress(e.target.value)}
							disabled={isView}
						/>
					</Autocomplete>
					{!isView && (
						<button
							type="button"
							onClick={clearDrawingOnClick}
							style={{ margin: "1px" }}
						>
							Clear Drawings
						</button>
					)}
					<GoogleMap
						id="search-map"
						mapContainerStyle={containerStyle}
						zoom={zoom}
						center={markerPosition}
						onLoad={onLoad}
						options={!isView ? enableMapOptions : disableMapOptions}
					>
						<Marker position={markerPosition} />
						<DrawingManager
							options={{
								drawingControl: true,
								drawingControlOptions: {
									drawingModes: [
										"polygon",
										"circle",
										"marker",
									], // Allow only polygons and circles
								},
							}}
							onPolygonComplete={(polygon) => {
								onDrawingPolygonCompleted(polygon);
							}}
							onCircleComplete={(circle) => {
								onDrawingCircleCompleted(circle);
							}}
							onMarkerComplete={(marker) => {
								onAddingMarkerCompleted(marker);
							}}
						></DrawingManager>
						{mapOutput.polygons &&
							mapOutput.polygons.map((polygon, index) => {
								return (
									<Polygon
										key={index}
										paths={polygon}
									></Polygon>
								);
							})}

						{mapOutput.circles &&
							mapOutput.circles.map((circle, index) => {
								return (
									<Circle
										key={index}
										center={circle.center}
										radius={circle.radius}
									></Circle>
								);
							})}

						{mapOutput.markers &&
							mapOutput.markers.map((marker, index) => {
								return (
									<Marker
										key={index}
										position={marker}
									></Marker>
								);
							})}
					</GoogleMap>
				</div>
			)}
		</div>
	);
};

export default React.memo(GoogleMapComponent);
