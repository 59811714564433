import React from "react";
import { withRouter } from "react-router-dom";
import { DASHBOARD_PATH, LAYOUT_TYPE } from "../../App/AppSettings";
import Layout from "../../Layout";
import { AuthConsumer } from "../../Shared/Authentication/AuthContext";
import { withTranslation } from "react-i18next";
import { LANGUAGE_KEYS } from "../../Shared/Constants/LanguageKeys";

const returnURL = DASHBOARD_PATH;

class Login extends React.Component {
  render() {
    const {t} = this.props;
    return (
      <React.Fragment>
        <Layout type={LAYOUT_TYPE.FORM} footer>
          <div className="main-content">
            <div className="login-container">
              <div className="container">
                <div className="login-options">
                  <div className="panel-group" id="accordion">
                    {/* <div className="panel panel-default corporate-login">
                      <div className="panel-heading">
                        <h4 className="panel-title">
                          <AuthConsumer>
                            {({ login }) => (
                              <div
                                data-toggle="collapse"
                                className="collapsed"
                                data-parent="#topaccordion"
                                onClick={() =>
                                  login(this.props.redirectURL, "corp")
                                }
                              >
                                {t(LANGUAGE_KEYS.BLS_PUBLICLOGIN_CONTENT_BUTTON_CORPORATELOGIN)}
                              </div>
                            )}
                          </AuthConsumer>
                        </h4>
                      </div>
                      <div
                        id="corporate-login"
                        className="panel-collapse collapse"
                      ></div>
                    </div> */}
                    <div className="panel panel-default individual-login">
                      <div className="panel-heading">
                        <h4 className="panel-title">
                          <AuthConsumer>
                            {({ login }) => (
                              <div
                                data-toggle="collapse"
                                className="collapsed"
                                data-parent="#topaccordion"
                                onClick={() =>
                                  login(this.props.redirectURL, "public")
                                }
                              >
                                {t(LANGUAGE_KEYS.BLS_PUBLICLOGIN_CONTENT_BUTTON_INDIVIDUALLOGIN)}
                              </div>
                            )}
                          </AuthConsumer>
                        </h4>
                      </div>
                      <div
                        id="individual-login"
                        className="panel-collapse collapse"
                      >
                        <div className="panel-body">
                          <div className="login-box"></div>
                        </div>
                      </div>
                    </div>
                    <div className="panel panel-default licence-id">
                      <div className="panel-heading">
                        <h4 className="panel-title">
                          {/* <a
                            data-toggle="collapse"
                            data-parent="#topaccordion"
                            href="#toplicence-id"
                          >
                            {t(LANGUAGE_KEYS.BLS_PUBLICLOGIN_CONTENT_BUTTON_BUSINESSIDLOGIN)}
                          </a> */}
                          <AuthConsumer>
                            {({ login }) => (
                              <a
                                data-toggle="collapse"
                                className="collapsed"
                                data-parent="#topaccordion"
                                onClick={() =>
                                  login(this.props.redirectURL, "public")
                                }
                              >
                                {t(LANGUAGE_KEYS.BLS_PUBLICLOGIN_CONTENT_BUTTON_BUSINESSIDLOGIN)}
                              </a>
                            )}
                          </AuthConsumer>
                        </h4>
                      </div>
                      <div
                        id="licence-id"
                        className="panel-collapse collapse in"
                      >
                        <div className="panel-body">
                          <div className="login-box"></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Layout>
      </React.Fragment>
    );
  }
}

Login.defaultProps = {
  redirectURL: returnURL
};

export default withTranslation()(withRouter(Login));
