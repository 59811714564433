import React from 'react';
import { withTranslation } from 'react-i18next';
import { withLoader } from '../../Shared/Loading';
import { Button, Nav, NavItem, NavLink } from 'reactstrap';
import { DATE_DISPLAY_FORMAT, BUSINESS_DETAILS_PATH, IS_DEMO, CITIZEN_DETAILS_PATH, VIOLATION_DETAILS_PATH } from '../../App/AppSettings';
import { navigateTo } from '../../Shared/Actions';
import { DIRECTIONS, MIN_FILTER_CHARS } from '../../Shared/Constants';
import {  LANGUAGE_KEYS } from '../../Shared/Constants/LanguageKeys';
import { ServerSideDataTableDemo } from '../../Shared/DataTable';
import { getCustomComponents } from '../../Shared/DataTable/BaseTable.js';
import DropdownButton from '../../Shared/DropdownButton/DropdownButton.js';
import { DateInput, SelectList, SMARTForm, Text } from '../../Shared/Forms';
import { FaExpand } from "react-icons/fa";

const FORM_CONTEXT = "Entities";
const LABEL_SIZE = 0;
const INPUT_SIZE = 12;
const ACTIONS = {
    WITHDRAW: "Withdraw",
    EDIT: "Edit",
    DELETE: "Delete"
}
const tabs = {
    BUSINESS_ENTITES: "BUSINESS_ENTITES",
    CITIZEN_ENTITES: "CITIZEN_ENTITES",
    VIOLATION: "VIOLATION"
}

const INBOX_FILTER = {
    SHOW_BUSINESS: tabs.BUSINESS_ENTITES,
    SHOW_CITIZEN: tabs.CITIZEN_ENTITES
}

const FORM_INITIAL_VALUES = {
    LicenceTypeId: '',
    ReferenceNo: '',
    Status: '',
    FromDate: '',
    ToDate: ''
};

const styles = {
    iframeContainer: {
      position: "relative",
      width: "100%",
      height: "700px",
      border: "1px solid #ccc",
    },
    iframe: {
      width: "100%",
      height: "100%",
      minHeight: "700px",
      border: "none",
    },
    fullscreenButton: {
      position: "absolute",
      top: "10px",
      right: "10px",
      backgroundColor: "#007bff",
      color: "white",
      border: "none",
      padding: "5px 10px",
      cursor: "pointer",
      borderRadius: "5px",
    },
};

const iframeSource = "https://cl-cph-stlucia-dev-6-0-client-linux.azurewebsites.net/AppHome/RedirectToBillUser?ispopup=false&isiframe=true";

class Entites extends React.Component {
    constructor(props) {
        super(props);
        //const { t } = this.props;
        this.state = {
            activeTab: tabs.BUSINESS_ENTITES,
            values: FORM_INITIAL_VALUES,
            businessEntitiesCount: 3,
            citizenEntitiesCount: 5,
            entityNameOptions: [],
            entityStatusOptions: [],
            licenceNameOptions: [],
            agencyNameOptions: [],
            licenceStatusOptions: [],
            showAdvancedSearch: false,
            tabNames: {
                BUSINESS_ENTITES: "Projects",
                CITIZEN_ENTITES: "Payment",
                VIOLATION: "Violations"
            }
            // tabNames: {
            //     BUSINESS_ENTITES: t(LANGUAGE_KEYS.BLS_PUBLICWORKSPACELICENCE_CONTENT_MENU_DUEFORRENEWAL),
            //     CITIZEN_ENTITES: t(LANGUAGE_KEYS.BLS_PUBLICWORKSPACELICENCE_CONTENT_MENU_ALL)
            // }
        }
    }

    componentDidMount = async () => {
        const { loader } = this.props;
        //start loading
        loader.start();

        const actionDropdowns = await this.getActionItems();

        this.setState({
            triggerRenewTable: Math.random(),
            extraGetParams: {},
            actionDropdowns
        })

        loader.done();
    }

    getActionItems = async () => {
        // const CODE_URL = `${GET_CODE_BY_CODE_CATEGORY}?categories=LicenceApplicationType`;
        // const response = await fetchRequest(CODE_URL, getParams());
        // const { IsSuccess, Data } = response.body;
        let deleteCodeId, editCodeId, withdrawCodeId;
        // if (response.success && IsSuccess) {
        //     const amendCode = Data.find(a => a.CodeTitle.toLowerCase() === ACTIONS.AMEND.toLowerCase());
        //     const renewCode = Data.find(a => a.CodeTitle.toLowerCase() === ACTIONS.RENEW.toLowerCase());
        //     const cancelCode = Data.find(a => a.CodeTitle.toLowerCase() === ACTIONS.CANCEL.toLowerCase());

        //     amendCodeId = amendCode ? amendCode.CodeName : null;
        //     renewCodeId = renewCode ? renewCode.CodeName : null;
        //     cancelCodeId = cancelCode ? cancelCode.CodeName : null;
        // }

        deleteCodeId = ACTIONS.DELETE.toLowerCase();
        editCodeId = ACTIONS.DELETE.toLowerCase();
        withdrawCodeId = ACTIONS.DELETE.toLowerCase();
        return { deleteCodeId, editCodeId, withdrawCodeId };
    }

    renderMobileCardActions = (RowData, tabView) => {
        return this.setActionOptions(RowData, tabView);
    }

    setActionOptions = (RowData, tabView) => {
        switch (tabView) {
            case tabs.BUSINESS_ENTITES:
                return this.businessEntityTab(RowData);

            case tabs.CITIZEN_ENTITES:
                return this.citizenEntityTab(RowData);

            default:
                return this.businessEntityTab(RowData);
        }
    }

    businessEntityTab = (RowData) => {
        return (
            <DropdownButton
                className={"btn-action"}
                buttonLabel="Actions"
                menuItems={
                    this.getBusinessEntityMenuItems(RowData)
                }
                onChange={(e) => this.handleSelectChange(e.key, RowData)}
                placement="bottomRight"
            />
        );
    }

    getBusinessEntityMenuItems = (RowData) => {
        const menuItems = [];
        // const { t } = this.props;
        // const { deleteCodeId, editCodeId, withdrawCodeId } = this.state.actionDropdowns;

        menuItems.push({
            label: ACTIONS.DELETE,
            value: ACTIONS.DELETE
        });
        menuItems.push({
            label: ACTIONS.EDIT,
            value: ACTIONS.EDIT
        });
        menuItems.push({
            label: ACTIONS.WITHDRAW,
            value: ACTIONS.WITHDRAW
        });
        return menuItems;
    }

    citizenEntityTab = (RowData) => {
        return (
            <DropdownButton
                className="btn-action"
                buttonLabel="Actions"
                menuItems={
                    this.getCitizenEntityMenuItems(RowData)
                }
                onChange={(e) => this.handleSelectChange(e.key, RowData)}
                placement="bottomRight"
            />
        );
    }

    getCitizenEntityMenuItems = (RowData) => {
        const menuItems = [];
        // const { t } = this.props;
        // const { deleteCodeId, editCodeId, withdrawCodeId } = this.state.actionDropdowns;

        // menuItems.push({
        //     label: deleteCodeId ? t(getLangKey(LANGUAGE_KEYS.BLS_CODE_LICENCEAPPLICATIONTYPE_TITLE_KEY, deleteCodeId)) : ACTIONS.DELETE,
        //     value: ACTIONS.DELETE
        // });
        // menuItems.push({
        //     label: editCodeId ? t(getLangKey(LANGUAGE_KEYS.BLS_CODE_LICENCEAPPLICATIONTYPE_TITLE_KEY, editCodeId)) : ACTIONS.EDIT,
        //     value: ACTIONS.EDIT
        // });
        // menuItems.push({
        //     label: withdrawCodeId ? t(getLangKey(LANGUAGE_KEYS.BLS_CODE_LICENCEAPPLICATIONTYPE_TITLE_KEY, withdrawCodeId)) : ACTIONS.WITHDRAW,
        //     value: ACTIONS.WITHDRAW
        // });
        menuItems.push({
            label: ACTIONS.DELETE,
            value: ACTIONS.DELETE
        });
        menuItems.push({
            label: ACTIONS.EDIT,
            value: ACTIONS.EDIT
        });
        menuItems.push({
            label: ACTIONS.WITHDRAW,
            value: ACTIONS.WITHDRAW
        });
        return menuItems;
    }

    toggleAdvancedSearch = () => {
        const { activeTab } = this.state;

        if (activeTab === tabs.BUSINESS_ENTITES) {
            this.setState((prevState) => {
                return {
                    extraGetParams: Object.assign({}, { inboxType: INBOX_FILTER.SHOW_BUSINESS }),
                    triggerBusinessTable: Math.random(),
                    showAdvancedSearch: !prevState.showAdvancedSearch,
                };
            });
        }
        else if (activeTab === tabs.CITIZEN_ENTITES) {
            this.setState((prevState) => {
                return {
                    extraGetParams: Object.assign({}, { inboxType: INBOX_FILTER.SHOW_CITIZEN }),
                    triggerCitizenTable: Math.random(),
                    showAdvancedSearch: !prevState.showAdvancedSearch,
                };
            });
        }
        else {
            return null;
        }

    }
    toggleTab = (tab) => {
        this.setState({
            activeTab: tab
        })
    }

    getCustomComponents = () => {
        let components = getCustomComponents();
        components.Filter = this.Filter;

        return components;
    }

    setContent = (rowData) => {
        return <div className='dashboard-application-link' onClick={() => this.redirectToBusinessPage(rowData)}>{rowData.EntityNumber}</div>;
    }

    redirectToBusinessPage = (rowData) => (
        navigateTo(BUSINESS_DETAILS_PATH, { business: rowData })
    )

    setMobileContent = (rowData) => {
        //const { t } = this.props;
        return (
            <tr key={'AppRefNum'}>
                <td width='40%' className='card-table-td card-table-title'><b>Entity Number</b>:</td>
                <td width='60%' className='card-table-td card-table-data'>{this.setContent(rowData)}</td>
            </tr>
        );
    }

    setCitizenContent = (rowData) => {
        return <div className='dashboard-application-link' onClick={() => this.redirectToCitizenPage(rowData)}>{rowData.CitizenNumber}</div>;
    }

    redirectToCitizenPage = (rowData) => {
         // navigateTo(CITIZEN_DETAILS_PATH, { citizen: rowData })
        window.location.href = "https://cl-cph-stlucia-dev-6-0-client-linux.azurewebsites.net/AppHome/RedirectToBillUser?ispopup=true";
    }
       
    setMobileCitizenContent = (rowData) => {
        //const { t } = this.props;
        return (
            <tr key={'AppRefNum'}>
                <td width='40%' className='card-table-td card-table-title'><b>Citizen Number</b>:</td>
                <td width='60%' className='card-table-td card-table-data'>{this.setCitizenContent(rowData)}</td>
            </tr>
        );
    }

    setViolationContent = (rowData) => {
        return <div className='dashboard-application-link' onClick={() => this.redirectToViolationPage(rowData)}>{rowData.ViolationNumber}</div>;
    }

    redirectToViolationPage = (rowData) => (
        navigateTo(VIOLATION_DETAILS_PATH, { citizen: rowData })
    )

    setMViolationobileContent = (rowData) => {
        //const { t } = this.props;
        return (
            <tr key={'AppRefNum'}>
                <td width='40%' className='card-table-td card-table-title'><b>Violation Number</b>:</td>
                <td width='60%' className='card-table-td card-table-data'>{this.setViolationContent(rowData)}</td>
            </tr>
        );
    }

    Filter = ({ setFilter }) => {
        const { t } = this.props;
        return (
            <div className={`${this.state.showAdvancedSearch ? "dashboard-search search-hidden" : "dashboard-search"}`}>
                <div className="search">
                    <div className="search-box">
                        <div className="search-cell search-head">{this.state.tabNames[this.state.activeTab]}</div>
                        <div className="search-cell">
                            <div className="input-group">
                                <input
                                    dir={DIRECTIONS.AUTO}
                                    type="text"
                                    name="filter"
                                    defaultValue={this.state.filter}
                                    className="form-control"
                                    placeholder={t(LANGUAGE_KEYS.BLS_PUBLICWORKSPACELICENCE_CONTENT_PLACEHOLDER_LICENCESEARCHBOX)}
                                    onChange={e => this.onFilterChange("filter", e.target.value, setFilter)}
                                />
                                <div className="input-group-append">
                                    <button className="btn btn-outline-secondary" type="button"
                                        onClick={e => this.onFilterChange("filter", this.state.filter, setFilter)}><i className="fal fa-search"></i></button>
                                </div>
                            </div>
                        </div>
                        <div className="search-cell advanced-search"><span onClick={this.toggleAdvancedSearch}>{t(LANGUAGE_KEYS.BLS_PUBLICWORKSPACELICENCE_CONTENT_TITLE_ADVANCEDSEARCH)}</span></div>
                    </div>
                </div>
            </div>
        );
    }

    render() {
        const { activeTab, showAdvancedSearch, businessEntitiesCount, citizenEntitiesCount, extraGetParams } = this.state;
        const { t } = this.props;
        return (
            <div id="Entities">
                <div className="inner-tabs">
                    <Nav tabs className="tab-nav-tabs">
                        <NavItem key={0} className="tab-nav-item">
                            <NavLink
                                className={"tab-header tab-nav-link"}
                                active={(activeTab === tabs.BUSINESS_ENTITES)}
                                id="Business"
                                onClick={() => this.toggleTab(tabs.BUSINESS_ENTITES)}
                            >
                                {this.state.tabNames.BUSINESS_ENTITES}<span>{businessEntitiesCount}</span>
                            </NavLink>
                        </NavItem>
                        <NavItem key={1} className="tab-nav-item">
                            <NavLink
                                className={"tab-header tab-nav-link"}
                                active={(activeTab === tabs.CITIZEN_ENTITES)}
                                id="Correspondences_All-tab"
                                onClick={() => {
                                    const iframe = document.getElementById('myIframe');
                                    if (iframe) {
                                        iframe.src = iframeSource;
                                    }
                                    this.toggleTab(tabs.CITIZEN_ENTITES)}}
                            >
                                {this.state.tabNames.CITIZEN_ENTITES}<span>{citizenEntitiesCount}</span>
                            </NavLink>
                        </NavItem>
                        {/* <NavItem key={2} className="tab-nav-item">
                            <NavLink
                                className={"tab-header tab-nav-link"}
                                active={(activeTab === tabs.VIOLATION)}
                                id="Correspondences_All-tab"
                                onClick={() => {
                                    const iframe = document.getElementById('myIframe');
                                    if (iframe) {
                                        iframe.src = iframeSource;
                                    }
                                    this.toggleTab(tabs.VIOLATION)}}
                            >
                                {this.state.tabNames.VIOLATION}<span>3</span>
                            </NavLink>
                        </NavItem> */}
                    </Nav>

                    {showAdvancedSearch &&
                        <SMARTForm
                            formContext={FORM_CONTEXT}
                            formValues={this.state.values}
                            isDummy={IS_DEMO}
                        >
                            {({ values, errors, onChange, onChangeField }) => (
                                <div className="dashboard-search">
                                    <div className={`${showAdvancedSearch ? "advanced-search-container search-shown" : "advanced-search-container search-hidden"}`}>
                                        <div className="advanced-serach-header">{t(LANGUAGE_KEYS.BLS_PUBLICWORKSPACELICENCE_CONTENT_TITLE_ADVANCEDSEARCH)}<span className="advanced-search-close">
                                            <i className="fal fa-times"
                                                onClick={this.toggleAdvancedSearch}></i></span></div>
                                        <div className="advanced-search-fields">
                                            <div className='advanced-search-cell '>
                                                <SelectList
                                                    name='LicenceTypeId'
                                                    value={values.LicenceTypeId}
                                                    options={this.state.entityNameOptions}
                                                    onChangeField={onChangeField}
                                                    placeholder={t(LANGUAGE_KEYS.BLS_PUBLICWORKSPACEAPPLICATION_CONTENT_TITLE_LICENCENAME)}
                                                    isMulti={false}
                                                    isClearable={true}
                                                    inputSize={INPUT_SIZE}
                                                    labelSize={LABEL_SIZE}
                                                    error={errors.LicenceTypeId}
                                                />
                                            </div>
                                            <div className='advanced-search-cell '>

                                                <SelectList
                                                    name='Status'
                                                    value={values.Status}
                                                    options={this.state.entityStatusOptions}
                                                    onChangeField={onChangeField}
                                                    placeholder={t(LANGUAGE_KEYS.BLS_PUBLICWORKSPACEAPPLICATION_CONTENT_TITLE_STATUS)}
                                                    isMulti={false}
                                                    isClearable={true}
                                                    inputSize={INPUT_SIZE}
                                                    labelSize={LABEL_SIZE}
                                                    error={errors.Status}
                                                />
                                            </div>
                                            <div className='advanced-search-cell '>
                                                <Text
                                                    name='ReferenceNo'
                                                    value={values.ReferenceNo}
                                                    placeholder={t(LANGUAGE_KEYS.BLS_PUBLICWORKSPACEAPPLICATION_CONTENT_TITLE_REFNUMBER)}
                                                    onChange={onChange}
                                                    minLength={0}
                                                    maxLength={140}
                                                    inputSize={INPUT_SIZE}
                                                    labelSize={LABEL_SIZE}
                                                    error={errors.ReferenceNo}
                                                />
                                            </div>
                                            <div className='advanced-search-cell calendar'>

                                                <DateInput
                                                    name='FromDate'
                                                    value={values.FromDate}
                                                    placeholder={t(LANGUAGE_KEYS.BLS_PUBLICWORKSPACEAPPLICATION_CONTENT_TITLE_FROMDATE)}
                                                    onChangeField={onChangeField}
                                                    date={true}
                                                    time={false}
                                                    max={new Date()}
                                                    format={DATE_DISPLAY_FORMAT.DATE}
                                                    inputSize={INPUT_SIZE}
                                                    labelSize={LABEL_SIZE}
                                                    error={errors.FromDate}
                                                    clearFieldEnabled={true}
                                                    disableKeyboardInput={true}
                                                />
                                            </div>
                                            <div className='advanced-search-cell calendar'>

                                                <DateInput
                                                    name='ToDate'
                                                    value={values.ToDate}
                                                    placeholder={t(LANGUAGE_KEYS.BLS_PUBLICWORKSPACEAPPLICATION_CONTENT_TITLE_TODATE)}
                                                    onChangeField={onChangeField}
                                                    date={true}
                                                    time={false}
                                                    max={new Date()}
                                                    format={DATE_DISPLAY_FORMAT.DATE}
                                                    inputSize={INPUT_SIZE}
                                                    labelSize={LABEL_SIZE}
                                                    error={errors.ToDate}
                                                    clearFieldEnabled={true}
                                                    disableKeyboardInput={true}
                                                />
                                            </div>
                                        </div>
                                        <div className="button-center">
                                            <Button
                                                color="forward"
                                                size="sm"
                                                type="button"
                                                onClick={() => this.searchLicenceInbox(values)}>
                                                {t(LANGUAGE_KEYS.BLS_PUBLICWORKSPACELICENCE_CONTENT_BUTTON_SEARCH)}</Button>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </SMARTForm>
                    }

                    {activeTab === tabs.BUSINESS_ENTITES && extraGetParams
                        &&
                        <ServerSideDataTableDemo
                            pageSize="5"
                            minFilterChars={MIN_FILTER_CHARS}
                            extraGetParams={extraGetParams}
                            dataType="entity"
                            //url={searchRenewURL}
                            //noResultsMessage={t(LANGUAGE_KEYS.BLS_PUBLICWORKSPACELICENCE_MESSAGE_NOTE_NOLICENCESFOUND)}
                            noResultsMessage={"No Business entity found"}
                            isCustomFilter={true}
                            key={this.state.triggerBusinessTable}
                            components={this.getCustomComponents()}
                            columns={
                                {
                                    EntityNumber: { title: "Number", width: '10%', DBkey: 'EntityNumber', setContent: this.setContent, setMobileContent: this.setMobileContent },
                                    EntityName: { title: "Project Name", width: '15%', DBkey: 'EntityName' },
                                    EntityType: { title: "Description", width: '15%', DBkey: 'PrimaryActivity' },
                                    Status: { title: "Project Status", width: '10%', DBkey: 'Status' },
                                    Location: { title: "Location", width: '15%', DBkey: 'StreetName' },
                                    StartDate: { title: "Created Date", isDate: true, width: '10%', DBkey: 'StartDate' },
                                    SLADate: { title: "End Date", isDate: true, width: '10%', DBkey: 'ExpiryDate' },
                                    CompletionPercentage: { title: "Completion Percentage", width: '15%', DBkey: 'CompletionPercentage' },
                                    Actions: { title: t(LANGUAGE_KEYS.BLS_PUBLICWORKSPACELICENCE_TABLE_TITLE_ACTIONS), width: '10%', DBkey: 'Actions' },
                                    SLAStatus: { title: "SLAStatus", width: '0%', DBkey: 'SLAStatus', visible: false },
                                }
                            }
                            renderActions={({ RowData }) => this.setActionOptions(RowData, tabs.BUSINESS_ENTITES)}
                            renderMobileCardActions={(RowData) => this.renderMobileCardActions(RowData, tabs.BUSINESS_ENTITES)}
                        />
                    }

                    {activeTab === tabs.CITIZEN_ENTITES && extraGetParams
                        &&
                        (<div id="iframe-container" style={styles.iframeContainer}>
                            <iframe style={styles.iframe} src={iframeSource} id="myIframe"></iframe>
                            <button style={styles.fullscreenButton} type="button" onClick={this.handleFullscreen}>
                                <FaExpand/>
                            </button>
                        </div>)
                    }
                    {activeTab === tabs.VIOLATION && extraGetParams &&
                        <ServerSideDataTableDemo
                            pageSize="5"
                            minFilterChars={MIN_FILTER_CHARS}
                            extraGetParams={extraGetParams}
                            dataType="violation"
                            //url={searchRenewURL}
                            //noResultsMessage={t(LANGUAGE_KEYS.BLS_PUBLICWORKSPACELICENCE_MESSAGE_NOTE_NOLICENCESFOUND)}
                            noResultsMessage={"No Business entity found"}
                            isCustomFilter={true}
                            key={this.state.triggerBusinessTable}
                            components={this.getCustomComponents()}
                            columns={
                                {
                                    ViolationNumber: { title: "Violation Number", width: '15%', DBkey: 'ViolationNumber', setContent: this.setViolationContent, setMobileContent: this.setViolationContent },
                                    ViolationType: { title: "Violation Type", width: '20%', DBkey: 'ViolationType' },
                                    ViolationStatus: { title: "Violation Status", width: '15%', DBkey: 'ViolationStatus' },
                                    ViolationDescription: { title: "Violation Description", width: '20%', DBkey: 'ViolationDescription' },
                                    ViolationRaisedDate: { title: "Violation Raised Date", width: '15%', DBkey: 'ViolationRaisedDate', isDate: true },
                                    ResponseDate: { title: "Response Date", width: '15%', DBkey: 'ResponseDate', isDate:true },
                                }
                            }
                            renderActions={({ RowData }) => this.setActionOptions(RowData, tabs.BUSINESS_ENTITES)}
                            renderMobileCardActions={(RowData) => this.renderMobileCardActions(RowData, tabs.BUSINESS_ENTITES)}
                        />
                    }
                </div>
            </div>
        )
    }

}

export default withTranslation()(withLoader(Entites));