import Griddle, { RowDefinition } from 'griddle-react';
import _ from 'lodash';
import React from 'react';
import { ERROR } from '../../Shared/Constants/LanguageKeys';
import { FILTER } from '../../Shared/Constants';
import { MobileCard } from '../../Shared/DataTable';
import { toastError } from '../../Shared/Forms';
import { withLoader } from '../../Shared/Loading';
import { DirectionContextConsumer } from '../DirectionContext';
import { getColDefinition, getCustomComponents } from './BaseTable';
import { withTranslation } from 'react-i18next';

const getGridData = async (dataType, currentPage, pageSize, filterText, sortColumn, sortDir, extraGetParams, currLang) => {
    await delay(500);
    if (dataType === "entity") {
        return {
            "Data": [
                {
                    EntityNumber: "ALC202141403",
                    EntityName: "Greenfield Apartments",
                    EntityType: "Local Company",
                    Status: "Planning",
                    StartDate:"15-May-2024",
                    IssuedDate: "15-May-2024",
                    ExpiryDate: "30-Nov-2025",
                    EntityDocuments: [{
                        FileDescription: "Entity Doc1", Filename: "entity_doc1.pdf", Id: 1, ContentType: "application/pdf", ContentLength: 0
                    },
                    {
                        FileDescription: "Entity Doc2", Filename: "entity_doc2.pdf", Id: 2, ContentType: "application/pdf", ContentLength: 0
                    }],
                    PostalCode: "642800", 
                    StreetName: "123 Greenfield Lane, Springfield", 
                    State: "Singapore", 
                    Country: "Singapore",
                    PrimaryActivity: "Construction of a 20-unit residential building",
                    PrimaryActivityDescription: "Construction of a 20-unit residential building",
                    SecondaryActivity: "Construction of a 20-unit residential building",
                    SecondaryActivityDescription: "Construction of a 20-unit residential building",
                    CompletionPercentage: "10%",
                    SLAStatus: "Rejected"
                },
                {
                    EntityNumber: "ALC201128431",
                    EntityName: "Lakeside Commercial Plaza",
                    EntityType: "Local Company",
                    Status: "Under Construction",
                    StartDate:"1-Aug-2024",
                    IssuedDate: "1-Aug-2024",
                    ExpiryDate: "15-Dec-2025",
                    EntityDocuments: [{
                        FileDescription: "Entity Doc3", Filename: "entity_doc3.pdf", Id: 3, ContentType: "application/pdf", ContentLength: 0
                    },
                    {
                        FileDescription: "Entity Doc4", Filename: "entity_doc4.pdf", Id: 4, ContentType: "application/pdf", ContentLength: 0
                    }],
                    PostalCode: "250048", 
                    StreetName: "456 Lakeside Blvd, Rivertown", 
                    State: "Singapore", 
                    Country: "Singapore",
                    PrimaryActivity: "Development of a retail and office complex near Lakeside",
                    PrimaryActivityDescription: "Development of a retail and office complex near Lakeside",
                    SecondaryActivity: "Development of a retail and office complex near Lakeside",
                    SecondaryActivityDescription: "Development of a retail and office complex near Lakeside",
                    CompletionPercentage: "50%",
                    SLAStatus: "Approved"
                },
                {
                    EntityNumber: "ARB52894659",
                    EntityName: "Heritage Park Renovation",
                    EntityType: "Business",
                    Status: "Under Construction",
                    StartDate:"2-Aug-2024",
                    IssuedDate: "2-Aug-2024",
                    ExpiryDate: "16-Sep-2025",
                    EntityDocuments: [{
                        FileDescription: "Entity Doc5", Filename: "entity_doc5.pdf", Id: 5, ContentType: "application/pdf", ContentLength: 0
                    },
                    {
                        FileDescription: "Entity Doc6", Filename: "entity_doc6.pdf", Id: 6, ContentType: "application/pdf", ContentLength: 0
                    }],
                    PostalCode: "404605", 
                    StreetName: "789 Heritage St, Oldtown", 
                    State: "Singapore", 
                    Country: "Singapore",
                    PrimaryActivity: "Restoration and upgrades to the existing park facilities",
                    PrimaryActivityDescription: "Restoration and upgrades to the existing park facilities",
                    SecondaryActivity: "Restoration and upgrades to the existing park facilities",
                    SecondaryActivityDescription: "Restoration and upgrades to the existing park facilities",
                    CompletionPercentage: "30%",
                    SLAStatus: "Review"
                }
            ],
            "IsSuccess": true,
            "Messages": [],
            "TotalRecordCount": 3
        }
    }
    else if (dataType === "citizen") {
        return {
            "Data": [
                {
                    CitizenNumber: "VIO0001",
                    Name: "Unauthorized Excavation",
                    Description: "Excavation work began without proper permits",
                    Location: "123 Greenfield Lane, Springfield",
                    Status: "Confirmed",
                    ViolationDate: "15-Jul-2024",
                    Gender: "Female",
                    DateOfBirth: "21-May-1987",
                    MaritalStatus:"Married",
                    AliveStatus: "Alive",
                    NumberOfChildren: 2,
                    Education: "Bachelor Degree"
                },
                {
                    CitizenNumber: "VIO0002",
                    Name: "Safety Code Non-Compliance",
                    Description: "Lack of safety barriers around the construction site",
                    Location: "456 Lakeside Blvd, Rivertown",
                    Status: "Escalated",
                    ViolationDate: "10-Sep-2024",
                    Gender: "Male",
                    DateOfBirth: "09-Dec-1986",
                    MaritalStatus:"Single",
                    AliveStatus: "Died",
                    NumberOfChildren: 0,
                    Education: "Master Degree"
                },
                {
                    CitizenNumber: "VIO0003",
                    Name: "Environmental Regulation Breach",
                    Description: "Cutting down trees without environmental clearance",
                    Location: "789 Heritage St, Oldtown",
                    Status: "In Progress",
                    ViolationDate: "1-Nov-2024",
                    Gender: "Male",
                    DateOfBirth: "09-Dec-1986",
                    MaritalStatus:"Single",
                    AliveStatus: "Died",
                    NumberOfChildren: 0,
                    Education: "Master Degree"
                }
            ],
            "IsSuccess": true,
            "Messages": [],
            "TotalRecordCount": 2
        }
    } 
    else if (dataType === "violation") {
        return {
            "Data": [
                {
                    ViolationNumber: "VN10001",
                    ViolationType: "Food and Health Concern Warning",
                    ViolationStatus: "Warning Letter",
                    ViolationDescription: "Warning report due to non-compliance",
                    ViolationRaisedDate: "28-Nov-2024",
                    ResponseDate: "1-Dec-2024"
                },
                {
                    ViolationNumber: "VN10002",
                    ViolationType: "Food and Health Concern Warning",
                    ViolationStatus: "Warning Letter",
                    ViolationDescription: "Warning report due to non-compliance",
                    ViolationRaisedDate: "29-Nov-2024",
                    ResponseDate: "3-Dec-2024"
                },
                {
                    ViolationNumber: "VN10003",
                    ViolationType: "Food and Health Concern",
                    ViolationStatus: "Escalation to Public Prosecution",
                    ViolationDescription: "Escalation to Public Prosecution due to lack of response",
                    ViolationRaisedDate: "30-Nov-2024",
                    ResponseDate: "15-Dec-2024"
                }
            ],
            "IsSuccess": true,
            "Messages": [],
            "TotalRecordCount": 2
        }
    }
    else return null;
}

function delay(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
}

class ServerSideDataTableDemo extends React.Component {
    constructor(props, context) {
        super(props, context);
        this.state = {
            dataType: this.props.dataType,
            gridData: [],
            currentPage: 1,
            recordCount: 0,
            filterText: { [FILTER]: "" },
            sortColumn: "",
            sortDir: "",
            width: window.innerWidth,
            renderMobileCardActions: this.props.renderMobileCardActions,
            toggleUpdate: false,
            isLoaded: false
        };
    }

    async componentDidMount() {
        const { currentPage, filterText, sortColumn, sortDir, dataType } = this.state;
        const { pageSize, loader, extraGetParams, getGridDataResponse, t } = this.props;
        const { currLang } = this.props.context;

        // Start loading
        loader.start();

        // Get grid data
        const response = await getGridData(dataType, currentPage, pageSize, filterText, sortColumn, sortDir, extraGetParams, currLang);

        // Done loading
        loader.done();
        if (response !== null) {
            const { IsSuccess, Data, TotalRecordCount } = response;

            if (getGridDataResponse !== undefined) {
                getGridDataResponse(response);
            }

            if (IsSuccess) {
                this.initGrid(Data, TotalRecordCount);
            }
            else {
                toastError(t(ERROR.LIST_RETRIEVAL_FAIL));
            }
        }
        else {
            toastError(t(ERROR.LIST_RETRIEVAL_FAIL));
        }
    }

    async componentDidUpdate(prevProps) {

        if (prevProps.toggleUpdate !== this.props.toggleUpdate) {
            const { currentPage, filterText, sortColumn, sortDir, dataType } = this.state;
            const { pageSize, loader, extraGetParams } = this.props;
            const { currLang } = this.props.context;

            // Start loading
            loader.start();

            // Get grid data
            const response = getGridData(dataType, currentPage, pageSize, filterText, sortColumn, sortDir, extraGetParams, currLang);

            // Done loading
            loader.done();

            // Update table state
            this.updateTableState(true, response, currentPage);
        }

        this.setAutocompleteOff();
    }

    componentWillMount() {
        window.addEventListener('resize', this.handleWindowSizeChange);
    };

    componentWillUnmount() {
        window.removeEventListener('resize', this.handleWindowSizeChange);
    };

    handleWindowSizeChange = () => {
        this.setState({ width: window.innerWidth });
    };

    setAutocompleteOff = () => {
        var inputElements = document.getElementsByTagName("input");
        for (var i = 0; inputElements[i]; i++) {
            inputElements[i].setAttribute("autocomplete", "off");
        }
    }

    initGrid = (data, recordCount) => {
        this.setState({
            gridData: data,
            recordCount: recordCount,
            isLoaded: true
        });
    }

    updateTableState = (toToggle, response, currentPage) => {
        const { IsSuccess, Data, TotalRecordCount } = response.body;
        const { t } = this.props;

        if (response.success && IsSuccess) {
            //Get previous page's data when current's page data is empty (except for Page 1)
            if (Data !== undefined && Data.length === 0 && currentPage !== 1) {
                this.getPrev();
            } else {
                this.setState({
                    gridData: Data,
                    currentPage: currentPage,
                    recordCount: TotalRecordCount
                });
            }

            // Update Grid
            if (toToggle) {
                this.setState({
                    toggleUpdate: !this.state.toggleUpdate
                })
            }
        }
        else {
            toastError(t(ERROR.LIST_RETRIEVAL_FAIL));
        }
    }

    togglePaginationDisplay = () => {
        if (this.state.recordCount === 0) {
            return "no-pagination";
        }
        return "";
    }

    getNext = async () => {
        const { currentPage, filterText, sortColumn, sortDir, dataType } = this.state;
        const { pageSize, loader, extraGetParams } = this.props;
        const { currLang } = this.props.context;

        let nextPage = currentPage + 1;

        // Start loading
        loader.start();

        // Get grid data
        const response = getGridData(dataType, nextPage, pageSize, filterText, sortColumn, sortDir, extraGetParams, currLang);

        // Done loading
        loader.done();

        // Update table state
        this.updateTableState(true, response, nextPage);
    }

    getPrev = async () => {
        const { currentPage, filterText, sortColumn, sortDir, dataType } = this.state;
        const { pageSize, loader, extraGetParams } = this.props;
        const { currLang } = this.props.context;

        let prevPage = currentPage - 1;

        // Start loading
        loader.start();

        // Get grid data
        const response = getGridData(dataType, prevPage, pageSize, filterText, sortColumn, sortDir, extraGetParams, currLang);

        // Done loading
        loader.done();

        // Update table state
        this.updateTableState(true, response, prevPage);
    }

    getPage = async (pageNumber) => {
        const { filterText, sortColumn, sortDir, dataType } = this.state;
        const { pageSize, loader, extraGetParams } = this.props;
        const { currLang } = this.props.context;

        // Start loading
        loader.start();

        // Get grid data
        const response = getGridData(dataType, pageNumber, pageSize, filterText, sortColumn, sortDir, extraGetParams, currLang);

        // Done loading
        loader.done();

        // Update table state
        this.updateTableState(true, response, pageNumber);
    }

    getCurrentPage = (e) => {
        let page = parseInt(e.target.value, 10);
        this.getPage(page);
    }

    applyFilter = (filterText) => {
        const { minFilterChars } = this.props;
        const minFilterCharacters = (typeof minFilterChars === 'undefined' ? 0 : minFilterChars);
        const searchFilterLength = filterText[FILTER].length;

        return (searchFilterLength >= minFilterCharacters) || (searchFilterLength === 0);
    }

    getFilter = async (filterText) => {
        const { sortColumn, sortDir, dataType } = this.state;
        const { isCustomFilter, pageSize, extraGetParams } = this.props;
        const { currLang } = this.props.context;

        // const { loader } = this.props;
        // Construct filterText
        filterText = isCustomFilter ? filterText : { [FILTER]: filterText };

        const toToggle = this.hasSelectFilterChange(filterText, this.state.filterText);

        await this.setState({ filterText });

        if (this.applyFilter(filterText)) {

            // // Start loading
            // loader.start();

            // Get grid data
            const response = getGridData(dataType, 1, pageSize, filterText, sortColumn, sortDir, extraGetParams, currLang);

            // // Done loading
            // loader.done();

            await this.setState({
                isFilter: true
            })

            // Update table state
            this.updateTableState(toToggle, response, 1);
        }
    }

    hasSelectFilterChange = (currentFilterText, prevFilterText) => {
        let currentFilterTextToCompare = Object.assign({}, currentFilterText);
        let prevFilterTextToCompare = Object.assign({}, prevFilterText);

        // Exclude search filter
        delete currentFilterTextToCompare[FILTER];
        delete prevFilterTextToCompare[FILTER];

        return !_.isEqual(currentFilterTextToCompare, prevFilterTextToCompare);
    }

    getSort = async (sortProperties) => {
        let col = sortProperties.id;
        let dir = sortProperties.sortAscending === true ? "asc" : "desc";
        this.setState({
            sortColumn: col,
            sortDir: dir
        });

        const { filterText, dataType } = this.state;
        const { pageSize, loader, extraGetParams } = this.props;
        const { currLang } = this.props.context;

        // Reset PageNumber to 1 when sortBy column is changed
        const pageNum = 1;

        // Start loading
        loader.start();

        // Get grid data
        const response = getGridData(dataType, pageNum, pageSize, filterText, col, dir, extraGetParams, currLang);

        // Done loading
        loader.done();

        // Update table state
        this.updateTableState(false, response, pageNum);
    }

    Filter = (props) => {
        return (
            <input
                type="text"
                name="filter"
                defaultValue={this.state.filterText[FILTER]}
                className={props.className}
                placeholder="Filter"
                onChange={(e) => props.setFilter(e.target.value)}
            />
        );
    };

    getImageCard = () => {
        const { noResultsMessage, graphicUrl, renderActions } = this.props;
        const { gridData, isLoaded } = this.state;

        return (
            <div className="row grid-list">
                {isLoaded && (gridData === undefined || gridData.length === 0) ?
                    <p>{noResultsMessage}</p>
                    :
                    gridData.map((file, index) => {
                        return (
                            <React.Fragment key={index}>
                                <div className={graphicUrl === file.Path ? "card grid-card grid-card-highlighted" : "card grid-card"}>
                                    <div className="card-headers white-background">
                                        <img className="card-img-graphic-select" src={this.getPath(file)} alt="Thumbnail" />
                                    </div>
                                    <div className="card-body">
                                        <div className="card-title">{file.Title}</div>
                                        <div className="card-information">{file.CreatedDate}</div>
                                        {renderActions({
                                            RowData: gridData[index],
                                            className: "action-button-group"
                                        })}
                                    </div>
                                </div>
                            </React.Fragment>
                        );
                    })}
            </div>
        )
    }

    getPath = (file) => {
        if (file.ContentType.includes("image")) {
            return this.props.imageUrl + file.Path;
        }
        else {
            return "/images/file.png"
        }
    }
    
    desktopLayout = ({ Table, Pagination, Filter }) => {
        return (
            <div>
                <Filter />
                <Table />
                <Pagination />
            </div>
        )
    }

    mobileLayout = ({ Filter, Table }) => {
        return (
            <div>
                <Filter />
                <Table />
            </div>
        )
    }

    mobileTable = () => {
        const { gridData, currentPage, recordCount } = this.state;
        const { pageSize } = this.props;
        const NoOfPage = Math.ceil(recordCount / pageSize);

        return (
            <MobileCard
                data={gridData}
                getPrev={this.getPrev}
                onChange={this.getCurrentPage}
                value={currentPage}
                NoOfPage={NoOfPage}
                getNext={this.getNext}
                getFirst={this.getFirst}
                getLast={this.getLast}
                columns={this.props.columns}
                currentPage={this.state.currentPage}
                renderMobileCardActions={this.props.renderMobileCardActions}
                noResultsMessage={this.props.noResultsMessage}
                filterExcludedFieldsArray={this.props.filterExcludedFieldsArray}
                graphicUrl={this.props.graphicUrl}
            />
        )
    }

    getComponents = (isMobile) => {
        const { isCustomLayout, isCustomFilter, components, isImageCard } = this.props;

        let customComponents = getCustomComponents();
        let dataTableComponents = components === undefined ? Object.assign({}, customComponents) : components;
        dataTableComponents.Filter = isCustomFilter ? components.Filter : this.Filter;

        if (isMobile) {
            dataTableComponents.Table = this.mobileTable;
        } else {
            dataTableComponents.Table = isImageCard ? this.getImageCard : customComponents.Table;
        }

        if(isCustomLayout) {
            dataTableComponents.Layout = components.Layout;
        } else {
            dataTableComponents.Layout = isMobile ? this.mobileLayout : this.desktopLayout;
        }

        return dataTableComponents;
    }

    render() {

        const { gridData, currentPage, recordCount, width, toggleUpdate } = this.state;
        const { pageSize, noResultsMessage } = this.props;

        const isMobile = width <= 992;
        let colDef = getColDefinition(this.props);

        return (
            <div className={"smart-griddle-table " + this.togglePaginationDisplay()}>
                <Griddle
                    key={isMobile + " " + toggleUpdate}
                    data={gridData}
                    pageProperties={{
                        currentPage,
                        pageSize,
                        recordCount
                    }}
                    textProperties={
                        this.noResultsMessage = { noResultsMessage }
                    }
                    components={this.getComponents(isMobile)}
                    events={{
                        onNext: this.getNext,
                        onPrevious: this.getPrev,
                        onGetPage: this.getPage,
                        onFilter: this.getFilter,
                        onSort: this.getSort,
                        onFirst: this.getFirst,
                        onLast: this.getLast
                    }}>
                    <RowDefinition>
                        {colDef}
                    </RowDefinition>
                </Griddle>
            </div>
        )
    }
}

const withDirectionContext = WrappedComponent => {
  class DirectionOnPageContext extends React.Component {
    render() {
      return (
        <DirectionContextConsumer>
          {({ currLang }) => (
            <WrappedComponent
              context={{ currLang }}
              {...this.props}
            />
          )}
        </DirectionContextConsumer>
      );
    }
  }

  return DirectionOnPageContext;
};

export default withTranslation()(withLoader(withDirectionContext(ServerSideDataTableDemo)));