import { faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { withTranslation } from 'react-i18next';
import { FormText, Input } from 'reactstrap';
import { DELETE_FILES_ARRAY } from '../../../Shared/Constants';
import { ERROR, LANGUAGE_KEYS } from '../../../Shared/Constants/LanguageKeys';
import { ErrorLabel } from '../../../Shared/Forms';
import { SMARTFormContext, SMARTSectionContext } from '../../../Shared/Forms/SMARTContext';

// HoC: Used by BaseControl to receive SMARTFormContext & SMARTSectionContext
const withSMARTContext = (WrappedComponent) => {
    class SMARTContext extends React.Component {
        render() {
            return (
                <SMARTFormContext.Consumer>
                    {(updateControlList) => (
                        <SMARTSectionContext.Consumer>
                            {(sectionName) => (
                                <WrappedComponent
                                    context={{
                                        updateControlList,
                                        sectionName
                                    }}
                                    {...this.props}
                                />
                            )}
                        </SMARTSectionContext.Consumer>
                    )}
                </SMARTFormContext.Consumer>
            );
        }
    }

    return SMARTContext;
};

class MultiFileUpload extends React.Component {

    // Lifecycle: Called once when component is mounted
    componentDidMount() {
        // Get context
        const { updateControlList, sectionName } = this.props.context;

        // Control calls callback to inform its parent SMARTForm their existence
        updateControlList && updateControlList(this.props.name, sectionName);
    }

    // Lifecycle: Optimize performance for Form onChange & validation
    // shouldComponentUpdate(nextProps, nextState) {
    //     return (
    //         this.props.value !== nextProps.value ||
    //         this.props.error !== nextProps.error ||
    //         !_.isEqual(this.props.options, nextProps.options) ||
    //         this.props.fileList !== nextProps.fileList ||
    //         this.props.disabled !== nextProps.disabled
    //     );
    // } 

    // Fn: Displays different color of label based on isError props
    ErrorField = (error) => {
        return (error) ? 'label-error' : 'label-default';
    };

    // Fn: Show/hide asterisk based on required prop from parent
    RequiredField = (required) => {
        return (required) ? 'required' : '';
    };

    // Fn: Handles on File changed/selected in File Explorer
    handleFileChange = async (e, { onChangeField }) => {
        const { t } = this.props;
        let file = e.currentTarget.files[0];

        // If file is selected
        if (file) {

            // Sieve out fileExtension
            let fileExtension = (/[.]/.exec(file.name)) ? /[^.]+$/.exec(e.currentTarget.files[0].name)[0] : undefined;

            if (!this.props.accept.includes(fileExtension)) {
                alert(t(ERROR.FILE_FORMAT_ERROR));
            }
            else if (file.size > this.props.bytesLimit) {
                alert(t(ERROR.FILE_SIZE_ERROR));
            }
            else {
                // Append unique key in file object
                // Key format: < UploadedFile_<TimeStamp> >
                Object.defineProperty(file, "key", {
                    writable: true,
                    value: `UploadedFile_${new Date().getTime()}.${fileExtension.toLowerCase()}`
                });

                // File state
                let fileList = this.props.fileList;
                fileList.push({ fileName: file.name, file: file, fileKey: file.key })

                // Set field to formik
                onChangeField(e.currentTarget.name, fileList);
            }

            e.currentTarget.value = null;
        }
    };

    handleDelete = (e, file, { onChangeField, deleteArray }) => {
        let fileList = this.props.fileList;

        for (let i = fileList.length - 1; i >= 0; --i) {
            if (fileList[i].fileKey === file.fileKey) {
                if (fileList[i].fileID !== undefined) {
                    deleteArray = deleteArray.concat(fileList[i].fileID)
                    onChangeField(DELETE_FILES_ARRAY, deleteArray)
                }
                fileList.splice(i, 1);
            }
        }

        // Update file name for display
        onChangeField(e.currentTarget.name, fileList);
    }

    render() {
        const { fileList, t } = this.props;
        return (
            <React.Fragment>
                <div className="documents-blog">
                    <div className={`documents-cell document-details 
                            ${fileList.length > 0 ? "active" : ""}`}>
                        <p className="name">{this.props.label}</p>
                        <p className="details">{this.props.description}</p>
                        <FormText color="muted" className="label-help">
                            {t(LANGUAGE_KEYS.BLS_PUBLICGENERAL_CONTENT_HELPTEXT_ALLOWEDFILEFORMAT)} {this.props.accept.map((format) => { return `${format}, ` })}<br />
                            {t(LANGUAGE_KEYS.BLS_PUBLICGENERAL_CONTENT_HELPTEXT_MAXIMUMFILESIZE)} {this.props.bytesLimit / 1000}KB
                        </FormText>
                        <ErrorLabel
                            message={this.props.error}
                            formatter={{ ...this.props, ...this.props.formatOptions }}
                        />
                    </div>
                    <div className="documents-cell document-action">
                        <label htmlFor={this.props.name}
                            color="primary" size="sm"
                            className="btn btn-bordered-small green upload-button">
                            {t(LANGUAGE_KEYS.BLS_PUBLICAPPLICATION_CONTENT_BUTTON_UPLOAD)}
                        </label>

                        <Input
                            type="file"
                            multiple={true}
                            name={this.props.name}
                            id={this.props.name}
                            onChange={(e) => this.handleFileChange(e, this.props)}
                            style={{ display: "none" }}
                        />
                    </div>

                    <div className="documents-cell document-uploaded">
                        {fileList.map((file) => {
                            return <p className="file-pdf icons" key={file.fileKey}>
                                <FontAwesomeIcon className="icon-trash-doc" icon={faTimesCircle} onClick={(e) => this.handleDelete(e, file, this.props)} />
                                {file.fileName}
                            </p>
                        })}

                    </div>
                </div>
            </React.Fragment>
        );
    }
}

// PropTypes: Defaulting value for optional props
MultiFileUpload.defaultProps = {
    accept: ['pdf', 'gif', 'jpg', 'jpeg', 'png', 'dwg'],
    bytesLimit: '5000000',
    label: '',
    error: '',
    formatOptions: {},
    required: false,
    className: ''
};

export default withTranslation()(withSMARTContext(MultiFileUpload));