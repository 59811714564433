import { default as React, useState } from 'react';
import { withTranslation } from 'react-i18next';
import { TABLE_LAYOUT } from '../../App/AppSettings';
import { AccordionContainer, Plaintext } from '../../Shared/Forms';
import { BigPreviewTable, SmallPreviewTable } from '../FormComponents/TableComponent';
import LicenceOverview from './LicenceOverview.js';
import { LANGUAGE_KEYS } from '../../Shared/Constants/LanguageKeys';
import { getLangKey } from '../../Content/DisplayComponents/DisplayUtils';
import utils from 'formiojs/utils';
import { fetchRequest, getParams } from '../../Shared/Actions';
import GoogleMapComponent from '../FormComponents/GoogleMapComponent/GoogleMapComponent.js';

const LABEL_SIZE = 6;
const INPUT_SIZE = 6;

const IS_UPPER = false;

class FormApplicationDetails extends React.Component {
    
    constructor(props) {
        super(props);
        this.state = {
            //Check whether webpage is loaded
            isLoaded: false
        };
    }

    formIOFieldsList = [];
    formIOSections = [];
    formIOFields = [];
    formIODatagridFields = [];
    formIODatagrids = [];
    formIODatagridFieldValues = [];
    htmlElementContent = "";

    toggleModal = (modalName, ID) => {
        this.setState({
            modalState: (this.state.modalState !== modalName) ? modalName : undefined,
            modalID: ID
        });
    };

    getFormIODataAsync = async (formIOUrl) => {
        const formIOData = await fetchRequest(formIOUrl, getParams(), false);
        const flattened = utils.flattenComponents(formIOData.body.components, true);
        this.htmlElementContent = this.getHtmlContent(flattened);
        console.log(this.htmlElementContent);
        const flattenedArray = Object.entries(flattened).filter(form => !form[0].includes("column"));
        this.formIOFieldsList = flattenedArray.map(([key, value]) => ({
            label: value.title ?? value.label,
            value: key,
            type: value.type
        }));
        this.mapFormIOFieldsToSections(this.formIOFieldsList);
    }

    mapFormIOFieldsToSections = (flattenedArray) => {
        flattenedArray.forEach(element => {
            if (element.type === "panel" && !this.formIOSections.find(section => section.value === element.value)) {
                this.formIOSections.push(element);
            } else if (element.type === "datagrid" && !this.formIODatagrids.find(datagrid => datagrid.value === element.value)) {
                const parentSection = this.formIOSections.find(section => element.value.includes(section.value));
                if (parentSection && !this.formIODatagrids.find(existingdatagrid => existingdatagrid[0].value === element.value)) {
                    this.formIODatagrids.push([element, parentSection.value]);
                }
            } else if (element.type !== "panel" && element.type !== "datagrid" && !this.formIOFields.find(field => field[0].value === element.value)) {
                const parentGrid = this.formIODatagrids.find(datagrid => element.value.includes(datagrid[0].value));
                if (parentGrid) {
                    if (!this.formIODatagridFields.find(field => field[0].value === element.value))
                        this.formIODatagridFields.push([element, parentGrid[0].value]);
                } else {
                    this.formIOFields.push(([element, this.formIOSections.length - 1]));
                }
            } 
        });
    }

    mapFormIODatagridValueToField = (values) => {
        if (this.formIODatagridFieldValues.length < Object.keys(values).filter(key => key.includes('Datagrid')).length) {
            for (const individualFieldKey in values) {
                const matchingDatagridField = this.formIODatagridFields.find(field => field[0].value.split('.')[field[0].value.split('.').length - 1] === 
                                                                                    individualFieldKey.split('.')[individualFieldKey.split('.').length - 1]);
                if (matchingDatagridField) {
                    this.formIODatagridFieldValues.push([matchingDatagridField[0], matchingDatagridField[1], values[individualFieldKey]]); 
                }
            }
        }
    }

    getHtmlContent(flattenedComponentArray) {
        for (const key in flattenedComponentArray) {
            if (flattenedComponentArray[key].type === "htmlelement") {
                return flattenedComponentArray[key].content;
            }
        }
    }

    render() {
        const { t } = this.props;
        const { values, toggleSection, sectionNames, applicationType, formDefinition } = this.props;
        const { LicenceDetails } = values;
        
        let LicenceData = '';

        if (LicenceDetails) {
            LicenceData = JSON.parse(LicenceDetails.LicenceData);
        }

        const applyValues = Object.assign({}, values.FormData.GeneralInformation, values.FormData.LicenceInformation);
        this.getFormIODataAsync(formDefinition.FormIOUrl);
        this.mapFormIODatagridValueToField(values.FormData.LicenceInformation);
        
        return (
            <>
                {applicationType === 'Cancel' && LicenceData &&
                    <AccordionContainer
                        title={sectionNames.CANCELLATION_INFORMATION.title}
                        active={sectionNames.CANCELLATION_INFORMATION.status}
                        onClick={toggleSection}
                        isIndividual={true}
                        sections={sectionNames}
                    >
                        <div className="card-body">
                            <div className="form-body form-preview">
                                <div className="multi-row-form">

                                    <Plaintext
                                        label={t(LANGUAGE_KEYS.BLS_PUBLICWORKSPACEAPPLICATION_CONTENT_FIELD_REMARKS)}
                                        name={'Remarks'}
                                        value={applyValues.Remarks}
                                        isUpper={IS_UPPER}
                                        labelSize={LABEL_SIZE}
                                        inputSize={INPUT_SIZE}
                                    />
                                    <Plaintext
                                        label={t(LANGUAGE_KEYS.BLS_PUBLICWORKSPACEAPPLICATION_CONTENT_FIELD_EFFECTIVEENDDATE)}
                                        name={'EndDate'}
                                        value={applyValues.EndDate}
                                        isUpper={IS_UPPER}
                                        labelSize={LABEL_SIZE}
                                        inputSize={INPUT_SIZE}
                                    />

                                </div>
                            </div>
                        </div>
                    </AccordionContainer>
                }
                {formDefinition.Sections !== undefined && formDefinition.Sections !== null && formDefinition.Sections.length !== 0 &&
                    <AccordionContainer
                        title={sectionNames.SERVICE_APP_DETAILS.title}
                        active={sectionNames.SERVICE_APP_DETAILS.status}
                        onClick={toggleSection}
                        isIndividual={true}
                        sections={sectionNames}
                    >
                        <div className="card-body">
                            <div className="form-body form-preview">
                                {
                                    formDefinition.Sections.map((section, index) => {
                                        return (
                                            <div key={index} className="multi-row-form">
                                                <h3>{t(getLangKey(LANGUAGE_KEYS.BLS_COMMONINFO_FORM_SECTION_KEY, section.Name))}</h3>
                                                {
                                                    section.Fields && section.Fields.map((field, findex) => {
                                                        if (field.DataSource === 'Remote') {
                                                            return (
                                                                <Plaintext
                                                                    key={findex}
                                                                    label={t(getLangKey(LANGUAGE_KEYS.BLS_COMMONINFO_FORM_FIELD_KEY, field.Name))}
                                                                    name={field.Name}
                                                                    value={t(getLangKey(LANGUAGE_KEYS.BLS_CODE_CATEGORY_TITLE_KEY, field.DataParameters.Category, applyValues[field.Name]))}
                                                                    isUpper={IS_UPPER}
                                                                    labelSize={LABEL_SIZE}
                                                                    inputSize={INPUT_SIZE}
                                                                />
                                                            );
                                                        } else {
                                                            return (
                                                                <Plaintext
                                                                    key={findex}
                                                                    label={t(getLangKey(LANGUAGE_KEYS.BLS_COMMONINFO_FORM_FIELD_KEY, field.Name))}
                                                                    name={field.Name}
                                                                    value={applyValues[field.Name]}
                                                                    isUpper={IS_UPPER}
                                                                    labelSize={LABEL_SIZE}
                                                                    inputSize={INPUT_SIZE}
                                                                />
                                                            );
                                                        }
                                                    })
                                                }
                                                {
                                                    section.Table && (section.Table.Fields.length <= TABLE_LAYOUT.COLUMNS) &&
                                                    <SmallPreviewTable
                                                        section={section}
                                                        values={values}
                                                        inputSize={INPUT_SIZE}
                                                    />
                                                }
                                                {section.Table && (section.Table.Fields.length > TABLE_LAYOUT.COLUMNS) &&
                                                    <BigPreviewTable
                                                        section={section}
                                                        values={values}
                                                        labelSize={LABEL_SIZE}
                                                        inputSize={INPUT_SIZE}
                                                    />
                                                }
                                            </div>
                                        );
                                    })
                                }
                            </div>
                        </div>

                    </AccordionContainer>
                }
                {formDefinition.FormIOUrl !== null && formDefinition.FormIOUrl !== undefined && 
                    <AccordionContainer
                        title={sectionNames.SERVICE_APP_DETAILS.title}
                        active={sectionNames.SERVICE_APP_DETAILS.status}
                        onClick={toggleSection}
                        isIndividual={true}
                        sections={sectionNames}
                    >
                        <div className="card-body">
                            <div className="form-body form-preview">
                            {this.formIOSections.map((section, index) => {
                                return (
                                    <div key={index} className="multi-row-form">
                                        <h3>{section.label}</h3>
                                        {this.formIOFields.filter(field => field[1] === index).map((field, findex) => {
                                            console.log(field);
                                            if (field[0].type === "map") {
                                                let filterMapValues = {};
                                                for (let key in applyValues) {
                                                    if (key.includes(field[0].value)) {
                                                        filterMapValues[key.split('.').slice(1).join('.')] = applyValues[key];
                                                    }
                                                }
                                                return (
                                                    <GoogleMapComponent
                                                        value={filterMapValues}
                                                        isView={true}
                                                    ></GoogleMapComponent>
                                                );
                                            } else if (field[0].type === "selectboxes") {
                                                let array = [];
                                                for (let key in applyValues) {
                                                    if (key.includes(field[0].value)) {
                                                        if (applyValues[key]) {
                                                            array = [...array, key.split('.')[1]];
                                                        }
                                                    }
                                                }
                                                const value = array.join(", ");
                                                return (
                                                    <Plaintext
                                                        key={findex}
                                                        label={field[0].label}
                                                        name={field[0].label}
                                                        value={value}
                                                        isUpper={IS_UPPER}
                                                        labelSize={LABEL_SIZE}
                                                        inputSize={INPUT_SIZE}
                                                    />);
                                            } else if (field[0].type === 'htmlelement') {
                                                return (
                                                    <div dangerouslySetInnerHTML={{ __html: this.htmlElementContent }} />
                                                )
                                            }else {
                                                return (
                                                <Plaintext
                                                    key={findex}
                                                    label={field[0].label}
                                                    name={field[0].label}
                                                    value={applyValues[field[0].value]}
                                                    isUpper={IS_UPPER}
                                                    labelSize={LABEL_SIZE}
                                                    inputSize={INPUT_SIZE}
                                                />);
                                            }
                                        })}
                                        {this.formIODatagrids.filter(grid => grid[1] === section.value).map((datagrid, index) => {
                                            return (
                                                <div key={index} className="multi-row-form">
                                                    <h3 key={index}>{datagrid[0].label}</h3>
                                                    {this.formIODatagridFieldValues.filter(field => field[1] === datagrid[0].value).map((field, index) => {
                                                        return (
                                                            <Plaintext
                                                                key={index}
                                                                label={field[0].label}
                                                                name={field[0].label}
                                                                value={field[2]}
                                                                isUpper={IS_UPPER}
                                                                labelSize={LABEL_SIZE}
                                                                inputSize={INPUT_SIZE}
                                                            />
                                                        );
                                                    })}
                                                </div>
                                            ) 
                                        })}
                                    </div>
                                );
                            })}
                            </div>
                        </div>
                    </AccordionContainer>
                }
                {
                    applicationType !== 'Apply' && LicenceData &&
                    <LicenceOverview
                        values={values}
                        toggleSection={toggleSection}
                        sectionNames={sectionNames}
                    />
                }
            </>
        );
    }
}
export default withTranslation()(FormApplicationDetails);
