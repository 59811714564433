import React from 'react';
import { withTranslation } from 'react-i18next';
import { Button, Nav, NavItem, NavLink } from 'reactstrap';
import {
    APPLICATION_DETAILS_PATH, APPLICATION_ENQUIRY_PARAMS_URL, DATE_DISPLAY_FORMAT, DRAFT_ENQUIRY_PARAMS_URL,
    GET_APPLICATION_DRAFT_INBOX_SERVER_SIDE_URL,
    GET_APPLICATION_INBOX_COUNT_URL, GET_APPLICATION_INBOX_SERVER_SIDE_URL, GET_APPLICATION_PENDING_PAYMENT_DATA_URL, IS_DEMO
} from '../../App/AppSettings';
import { fetchRequest, getParams, navigateTo } from '../../Shared/Actions';
import { DIRECTIONS, FILTER, MIN_FILTER_CHARS } from '../../Shared/Constants';
import { ERROR, LANGUAGE_KEYS } from '../../Shared/Constants/LanguageKeys';
import { ServerSideDataTable } from '../../Shared/DataTable';
import { getCustomComponents } from '../../Shared/DataTable/BaseTable.js';
import DropdownButton from '../../Shared/DropdownButton/DropdownButton.js';
import { DateInput, SelectList, SMARTForm, Text, toastError } from '../../Shared/Forms';
import { arrayToSelectListOptions, arrayToTranslatedLicenceNameSelectListOptions } from '../../Shared/Forms/FormUtils.js';
import { withLoader } from '../../Shared/Loading';
import { getLangKey, getLicenceTypeNameTranslationKey } from '../DisplayComponents/DisplayUtils';
import DraftInbox from './DraftInbox';

const FORM_CONTEXT = 'Applications';
const LABEL_SIZE = 0;
const INPUT_SIZE = 12;

const INBOX_FILTER = {
    SHOW_ALL: 'ALL',
    SHOW_REQUIRE_ATTENTION: 'REQUIRE_ATTENTION'
};

//data table server side url
const APPLICATION_INBOX_URL = GET_APPLICATION_INBOX_SERVER_SIDE_URL;
const APPLICATION_DRAFT_INBOX_URL = GET_APPLICATION_DRAFT_INBOX_SERVER_SIDE_URL;

const APPLICATION_INBOX_COUNT_URL = GET_APPLICATION_INBOX_COUNT_URL;

// Application Enquiry Search
const PARAMS_URL = APPLICATION_ENQUIRY_PARAMS_URL;

// Draft Enquiry Search
const DRAFT_PARAMS_URL = DRAFT_ENQUIRY_PARAMS_URL;

const ACTIONS = {
    RESPOND_TO_ACTION: 'Respond to Action',
    PAYMENT: 'Submit Payment'
};

const FORM_INITIAL_VALUES = {
    LicenceTypeId: '',
    ReferenceNo: '',
    Status: '',
    FromDate: '',
    ToDate: ''
};

const tabs = {
    REQUIRE_ATTENTION: `REQUIRE_ATTENTION`,
    DRAFTS: `DRAFTS`,
    ALL: `ALL`
};

class Applications extends React.Component {
    constructor(props) {
        super(props);
        const { t } = this.props;
        this.state = {
            activeTab: tabs.REQUIRE_ATTENTION,
            values: FORM_INITIAL_VALUES,
            formNameOptions: [],
            formTypeOptions: [],
            statusOptions: [],
            referenceNoOptions: [],
            filter: '',
            showAdvancedSearch: false,
            tabNames: {
                REQUIRE_ATTENTION: t(LANGUAGE_KEYS.BLS_PUBLICWORKSPACEAPPLICATION_CONTENT_BUTTON_ATTENTIONREQUIRED),
                DRAFTS: t(LANGUAGE_KEYS.BLS_PUBLICWORKSPACEAPPLICATION_CONTENT_BUTTON_DRAFTS),
                ALL: t(LANGUAGE_KEYS.BLS_PUBLICWORKSPACEAPPLICATION_CONTENT_BUTTON_ALL)
            }
        };
    }


    componentDidMount = async () => {
        const { loader } = this.props;

        // Get data
        // Start loading
        loader.start();

        this.setState({
            searchURL: APPLICATION_INBOX_URL,
            searchDraftURL: APPLICATION_DRAFT_INBOX_URL,
            triggerRfaTable: Math.random(),
        });

        this.setCountData();
        this.getExtraParams();
        this.getApplicationFilterParams(tabs.REQUIRE_ATTENTION);

        // Done loading
        loader.done();
    }

    getCountData = async (url) => {

        const { t } = this.props;
        const response = await fetchRequest(url, getParams(), IS_DEMO);
        const { IsSuccess, AppRequireAttentionCount, AppDraftCount, AppAllCount } = response.body;

        // If success, load retrieved data into state
        if (IsSuccess) {
            return {
                appRequireAttentionCount: AppRequireAttentionCount,
                appDraftCount: AppDraftCount,
                appAllCount: AppAllCount
            };
        }
        else {
            toastError(t(ERROR.LIST_RETRIEVAL_FAIL));

            return {
                appRequireAttentionCount: 0,
                appDraftCount: 0,
                appAllCount: 0
            };

        }
    }

    refreshDraftData = async () => {

        this.setState({
            triggerDraftTable: Math.random(),
        });
        this.setCountData();
    }

    setCountData = async () => {
        const countData = await this.getCountData(APPLICATION_INBOX_COUNT_URL);

        this.setState({
            appRequireAttentionCount: countData.appRequireAttentionCount,
            appDraftCount: countData.appDraftCount,
            appAllCount: countData.appAllCount
        });

    }

    getApplicationFilterParams = async (activeTab) => {
        const inboxTab = (activeTab === tabs.REQUIRE_ATTENTION) ? INBOX_FILTER.SHOW_REQUIRE_ATTENTION : INBOX_FILTER.SHOW_ALL;
        const { t } = this.props;
        const response = await fetchRequest(PARAMS_URL + inboxTab, getParams(), IS_DEMO);
        const { IsSuccess, Data, Messages } = response.body;

        if (IsSuccess) {
            const { FormName, Status } = Data;
            const formNameOptions = [];
            const statusOptions = [];

            // Format filter params into SelectList format
            arrayToTranslatedLicenceNameSelectListOptions(FormName, formNameOptions, this.props.t);
            arrayToSelectListOptions(Status, statusOptions);

            this.setState({
                formNameOptions,
                statusOptions,
            });
        }
        else {
            toastError(t(getLangKey(ERROR.BACKEND_ERROR_MESSAGE, Messages)), Messages);
        }
    }

    getApplicationDraftFilterParams = async () => {
        const { t } = this.props;
        const response = await fetchRequest(DRAFT_PARAMS_URL, getParams(), IS_DEMO);
        const { IsSuccess, Data, Messages } = response.body;

        if (IsSuccess) {
            const { FormType } = Data;
            const formTypeOptions = [];

            // Format filter params into SelectList format
            arrayToTranslatedLicenceNameSelectListOptions(FormType, formTypeOptions, this.props.t);

            this.setState({
                ...this.state,
                formTypeOptions
            });
        }
        else {
            toastError(t(getLangKey(ERROR.BACKEND_ERROR_MESSAGE, Messages)), Messages);
        }
    }


    setContent = (rowData) => {
        return <div className='dashboard-application-link' onClick={() => this.redirectToRFAPage(rowData)}>{rowData.ApplicationRefNumber}</div>;
    }

    setLicenceNameTranslatedContent = (rowData) => {
        const { t } = this.props;
        const licenceTypeNameTranslationKey = getLicenceTypeNameTranslationKey(rowData.LicenceName);
        return (
            <>{t(getLangKey(LANGUAGE_KEYS.BLS_SCOPE_LICENCETYPE_LICENCETYPENAME_KEY, licenceTypeNameTranslationKey, rowData.LicenceTypeId))}</>
        );
    }

    setAppTypeTranslatedContent = (rowData) => {
        const { t } = this.props;
        return (
            <>{t(getLangKey(LANGUAGE_KEYS.BLS_CODE_LICENCEAPPLICATIONTYPE_TITLE_KEY, rowData.ApplicationTypeCodeName))}</>
        );
    }

    setMobileContent = (rowData) => {
        const { t } = this.props;
        return (
            <tr key={'AppRefNum'}>
                <td width='40%' className='card-table-td card-table-title'><b>{t(LANGUAGE_KEYS.BLS_PUBLICWORKSPACEAPPLICATION_CONTENT_TITLE_APPLICATIONREFNUMBER)}</b>:</td>
                <td width='60%' className='card-table-td card-table-data'>{this.setContent(rowData)}</td>
            </tr>
        );
    }

    handleSelectChange = (key, rowData) => {
        if (key === ACTIONS.RESPOND_TO_ACTION) {
            return this.redirectToRFAPage(rowData);
        }
        if (key === ACTIONS.PAYMENT) {
            return this.redirectToPayment(rowData);
        }
        return null;
    };

    redirectToRFAPage = (rowData) => (
        navigateTo(APPLICATION_DETAILS_PATH, { application: rowData })
    )

    redirectToPayment = async (rowData) => {

        const { loader, t } = this.props;
        loader.start();
        const response = await fetchRequest(GET_APPLICATION_PENDING_PAYMENT_DATA_URL + rowData.ApplicationRefNumber, getParams(), false);
        const { IsSuccess, Data } = response.body;

        if (IsSuccess) {
            loader.done();
            navigateTo(Data.RedirectURL, { Data: Data.SubmissionNumber });
        } else {
            loader.done();
            toastError(t(ERROR.PAYMENT_REDIRECT_FAIL));

        }
    }

    toggleTab = (activeTab) => {
        this.setState({
            activeTab
        }, () => {
            if (activeTab === tabs.DRAFTS) {
                this.getApplicationDraftFilterParams();
                this.getExtraParams();
            } else {
                this.getApplicationFilterParams(activeTab);
                this.getExtraParams();
            }
        });
    }

    toggleAdvancedSearch = () => {
        const { activeTab } = this.state;

        if (activeTab === tabs.REQUIRE_ATTENTION) {
            this.setState((prevState) => {
                return {
                    extraGetParams: Object.assign({}, { inboxType: INBOX_FILTER.SHOW_REQUIRE_ATTENTION }),
                    triggerRfaTable: Math.random(),
                    showAdvancedSearch: !prevState.showAdvancedSearch,
                };
            });
        }
        else if (activeTab === tabs.ALL) {
            this.setState((prevState) => {
                return {
                    extraGetParams: Object.assign({}, { inboxType: INBOX_FILTER.SHOW_ALL }),
                    triggerAllTable: Math.random(),
                    showAdvancedSearch: !prevState.showAdvancedSearch,
                };
            });
        }
        else if (activeTab === tabs.DRAFTS) {
            this.setState((prevState) => {
                return {
                    triggerDraftTable: Math.random(),
                    showAdvancedSearch: !prevState.showAdvancedSearch,
                };
            });
        }
        else {
            return null;
        }
    }

    getCustomComponents = () => {
        const components = getCustomComponents();
        components.Filter = this.Filter;

        return components;
    }

    onFilterChange = (filterName, value, setFilter) => {
        this.setState({
            [filterName]: value
        });
        const filterFields = {
            [FILTER]: value
        };
        setFilter(filterFields);
    }

    Filter = ({ setFilter }) => {
        const { t } = this.props;
        return (
            <div className={`${this.state.showAdvancedSearch ? 'dashboard-search search-hidden' : 'dashboard-search'}`}>
                <div className='search'>
                    <div className='search-box'>
                        <div className='search-cell search-head'>{this.state.tabNames[this.state.activeTab]}</div>
                        <div className='search-cell'>
                            <div className='input-group'>
                                <input
                                    dir={DIRECTIONS.AUTO}
                                    type='text'
                                    name='filter'
                                    defaultValue={this.state.filter}
                                    className='form-control'
                                    placeholder={t(LANGUAGE_KEYS.BLS_PUBLICWORKSPACEAPPLICATION_CONTENT_PLACEHOLDER_WORKSPACESEARCHBOX)}
                                    onChange={e => this.onFilterChange('filter', e.target.value, setFilter)}
                                />
                                <div className='input-group-append'>
                                    <button className='btn btn-outline-secondary' type='button'
                                        onClick={() => this.onFilterChange('filter', this.state.filter, setFilter)}><i className='fal fa-search'></i></button>
                                </div>
                            </div>
                        </div>
                        <div className='search-cell advanced-search'><span onClick={this.toggleAdvancedSearch}>{t(LANGUAGE_KEYS.BLS_PUBLICWORKSPACEAPPLICATION_CONTENT_TITLE_ADVANCEDSEARCH)}</span></div>
                    </div>
                </div>
            </div>

        );
    }

    getMenuItems = (RowData) => {
        const menuItems = [];
        const { t } = this.props;

        if (RowData.IsRfa) {
            menuItems.push({ label: t(LANGUAGE_KEYS.BLS_PUBLICWORKSPACEAPPLICATION_CONTENT_BUTTON_RESPONDTOACTION), value: ACTIONS.RESPOND_TO_ACTION });
        }
        if (RowData.IsPayment) {
            menuItems.push({ label: t(LANGUAGE_KEYS.BLS_PUBLICWORKSPACEAPPLICATION_CONTENT_BUTTON_SUBMITPAYMENT), value: ACTIONS.PAYMENT });
        }
        return menuItems;
    }

    searchNonDraftInbox = (values) => {
        const { activeTab } = this.state;

        if (activeTab === tabs.REQUIRE_ATTENTION) {
            this.setState({
                extraGetParams: Object.assign({}, values, { inboxType: INBOX_FILTER.SHOW_REQUIRE_ATTENTION }),
                triggerRfaTable: Math.random()
            });
        }
        else {
            if (activeTab === tabs.ALL) {
                this.setState({
                    extraGetParams: Object.assign({}, values, { inboxType: INBOX_FILTER.SHOW_ALL }),
                    triggerAllTable: Math.random()
                });
            }
        }
    }

    searchDraftInbox = (values) => {
        this.setState({
            extraGetParams: values,
            triggerDraftTable: Math.random()
        });
    }

    getExtraParams = () => {
        const { activeTab } = this.state;

        if (activeTab === tabs.REQUIRE_ATTENTION) {
            this.setState({
                extraGetParams: { inboxType: INBOX_FILTER.SHOW_REQUIRE_ATTENTION }
            });
        }
        else if (activeTab === tabs.ALL) {
            this.setState({
                extraGetParams: { inboxType: INBOX_FILTER.SHOW_ALL }
            });
        }
        else if (activeTab === tabs.DRAFTS) {
            this.setState({
                extraGetParams: {}
            });
        }
    }

    renderMobileCardActions = (RowData) => {
        const { t } = this.props;
        return (
            <React.Fragment>
                <DropdownButton
                    className='btn-action'
                    buttonLabel={t(LANGUAGE_KEYS.BLS_PUBLICWORKSPACEAPPLICATION_CONTENT_TITLE_ACTIONS)}
                    disabled={this.getMenuItems(RowData).length > 0 ? false : true}
                    menuItems={
                        this.getMenuItems(RowData)
                    }
                    onChange={(e) => this.handleSelectChange(e.key, RowData)}
                    placement='bottomRight'
                />
            </React.Fragment>
        );
    }

    render() {
        const { activeTab, extraGetParams, searchURL, searchDraftURL, appRequireAttentionCount, appDraftCount, appAllCount } = this.state;
        const inboxTab = (activeTab === tabs.REQUIRE_ATTENTION) ? INBOX_FILTER.SHOW_REQUIRE_ATTENTION : INBOX_FILTER.SHOW_ALL;
        const { t } = this.props;
        return (
            <React.Fragment>
                <div id='Applications'>
                    <div className='inner-tabs'>
                        <Nav tabs className='tab-nav-tabs'>
                            <NavItem key={0} className='tab-nav-item'>
                                <NavLink
                                    className={'tab-header tab-nav-link'}
                                    active={(activeTab === tabs.REQUIRE_ATTENTION)}
                                    id='Require_Attention-tab'
                                    onClick={() => this.toggleTab(tabs.REQUIRE_ATTENTION)}
                                >
                                    {t(LANGUAGE_KEYS.BLS_PUBLICWORKSPACEAPPLICATION_CONTENT_BUTTON_ATTENTIONREQUIRED)}<span>{appRequireAttentionCount}</span>
                                </NavLink>
                            </NavItem>
                            <NavItem key={1} className='tab-nav-item'>
                                <NavLink
                                    className={'tab-header tab-nav-link'}
                                    active={(activeTab === tabs.DRAFTS)}
                                    id='Drafts-tab'
                                    onClick={() => this.toggleTab(tabs.DRAFTS)}
                                >
                                    {t(LANGUAGE_KEYS.BLS_PUBLICWORKSPACEAPPLICATION_CONTENT_BUTTON_DRAFTS)}<span>{appDraftCount}</span>
                                </NavLink>
                            </NavItem>
                            <NavItem key={2} className='tab-nav-item'>
                                <NavLink
                                    className={'tab-header tab-nav-link'}
                                    active={(activeTab === tabs.ALL)}
                                    id='Applications_All-tab'
                                    onClick={() => this.toggleTab(tabs.ALL)}
                                >
                                    {t(LANGUAGE_KEYS.BLS_PUBLICWORKSPACEAPPLICATION_CONTENT_BUTTON_ALL)}<span>{appAllCount}</span>
                                </NavLink>
                            </NavItem>
                        </Nav>

                        {this.state.showAdvancedSearch && activeTab !== tabs.DRAFTS &&
                            <SMARTForm
                                key={inboxTab}
                                formContext={FORM_CONTEXT}
                                formValues={this.state.values}
                                isDummy={IS_DEMO}
                            >
                                {({ values, errors, onChange, onChangeField }) => (
                                    <div className='dashboard-search'>
                                        <div className={`${this.state.showAdvancedSearch ? 'advanced-search-container search-shown' : 'advanced-search-container search-hidden'}`}>
                                            <div className='advanced-serach-header'>{t(LANGUAGE_KEYS.BLS_PUBLICWORKSPACEAPPLICATION_CONTENT_TITLE_ADVANCEDSEARCH)}<span className='advanced-search-close'>
                                                <i className='fal fa-times'
                                                    onClick={this.toggleAdvancedSearch}></i></span></div>
                                            <div className='advanced-search-fields'>
                                                <div className='advanced-search-cell '>
                                                    <SelectList
                                                        name='LicenceTypeId'
                                                        value={values.LicenceTypeId}
                                                        options={this.state.formNameOptions}
                                                        onChangeField={onChangeField}
                                                        placeholder={t(LANGUAGE_KEYS.BLS_PUBLICWORKSPACEAPPLICATION_CONTENT_TITLE_SERVICENAME)}
                                                        isMulti={false}
                                                        isClearable={true}
                                                        inputSize={INPUT_SIZE}
                                                        labelSize={LABEL_SIZE}
                                                        error={errors.LicenceTypeId}
                                                    />
                                                </div>
                                                <div className='advanced-search-cell '>

                                                    <SelectList
                                                        name='Status'
                                                        value={values.Status}
                                                        options={this.state.statusOptions}
                                                        onChangeField={onChangeField}
                                                        placeholder={t(LANGUAGE_KEYS.BLS_PUBLICWORKSPACEAPPLICATION_CONTENT_TITLE_STATUS)}
                                                        isMulti={false}
                                                        isClearable={true}
                                                        inputSize={INPUT_SIZE}
                                                        labelSize={LABEL_SIZE}
                                                        error={errors.Status}
                                                    />
                                                </div>
                                                <div className='advanced-search-cell '>

                                                    <Text
                                                        name='ReferenceNo'
                                                        value={values.ReferenceNo}
                                                        placeholder={t(LANGUAGE_KEYS.BLS_PUBLICWORKSPACEAPPLICATION_CONTENT_TITLE_REFNUMBER)}
                                                        onChange={onChange}
                                                        minLength={0}
                                                        maxLength={140}
                                                        inputSize={INPUT_SIZE}
                                                        labelSize={LABEL_SIZE}
                                                        error={errors.ReferenceNo}
                                                    />
                                                </div>
                                                <div className='advanced-search-cell calendar'>

                                                    <DateInput
                                                        name='FromDate'
                                                        value={values.FromDate}
                                                        placeholder={t(LANGUAGE_KEYS.BLS_PUBLICWORKSPACEAPPLICATION_CONTENT_TITLE_FROMDATE)}
                                                        onChangeField={onChangeField}
                                                        date={true}
                                                        time={false}
                                                        max={new Date()}
                                                        format={DATE_DISPLAY_FORMAT.DATE}
                                                        inputSize={INPUT_SIZE}
                                                        labelSize={LABEL_SIZE}
                                                        error={errors.FromDate}
                                                        clearFieldEnabled={true}
                                                        disableKeyboardInput={true}
                                                    />
                                                </div>
                                                <div className='advanced-search-cell calendar'>

                                                    <DateInput
                                                        name='ToDate'
                                                        value={values.ToDate}
                                                        placeholder={t(LANGUAGE_KEYS.BLS_PUBLICWORKSPACEAPPLICATION_CONTENT_TITLE_TODATE)}
                                                        onChangeField={onChangeField}
                                                        date={true}
                                                        time={false}
                                                        max={new Date()}
                                                        format={DATE_DISPLAY_FORMAT.DATE}
                                                        inputSize={INPUT_SIZE}
                                                        labelSize={LABEL_SIZE}
                                                        error={errors.ToDate}
                                                        clearFieldEnabled={true}
                                                        disableKeyboardInput={true}
                                                    />
                                                </div>
                                            </div>
                                            <div className='button-center'>
                                                <Button
                                                    color='forward'
                                                    size='sm'
                                                    type='button'
                                                    onClick={() => this.searchNonDraftInbox(values)}>
                                                    {t(LANGUAGE_KEYS.BLS_PUBLICWORKSPACEAPPLICATION_CONTENT_BUTTON_SEARCH)}</Button>
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </SMARTForm>
                        }

                        {/* showAdvancedSearch for draft */}
                        {this.state.showAdvancedSearch && activeTab === tabs.DRAFTS &&
                            <SMARTForm
                                key={inboxTab}
                                formContext={FORM_CONTEXT}
                                formValues={this.state.values}
                                isDummy={IS_DEMO}

                            >
                                {({ values, errors, onChangeField }) => (
                                    <div className='dashboard-search'>
                                        <div className={`${this.state.showAdvancedSearch ? 'advanced-search-container search-shown' : 'advanced-search-container search-hidden'}`}>
                                            <div className='advanced-serach-header'> {t(LANGUAGE_KEYS.BLS_PUBLICWORKSPACEAPPLICATION_CONTENT_TITLE_ADVANCEDSEARCH)}<span className='advanced-search-close'>
                                                <i className='fal fa-times'
                                                    onClick={this.toggleAdvancedSearch}></i></span></div>
                                            <div className='advanced-search-fields'>
                                                <div className='advanced-search-cell '>
                                                    <SelectList
                                                        name='LicenceTypeId'
                                                        value={values.LicenceTypeId}
                                                        options={this.state.formTypeOptions}
                                                        onChangeField={onChangeField}
                                                        placeholder={t(LANGUAGE_KEYS.BLS_PUBLICWORKSPACEAPPLICATION_CONTENT_TITLE_SERVICENAME)}
                                                        isMulti={false}
                                                        isClearable={true}
                                                        inputSize={INPUT_SIZE}
                                                        labelSize={LABEL_SIZE}
                                                        error={errors.LicenceTypeId}
                                                    />
                                                </div>
                                                <div className='advanced-search-cell calendar'>

                                                    <DateInput
                                                        name='FromDate'
                                                        value={values.FromDate}
                                                        placeholder={t(LANGUAGE_KEYS.BLS_PUBLICWORKSPACEAPPLICATION_CONTENT_TITLE_FROMDATE)}
                                                        onChangeField={onChangeField}
                                                        date={true}
                                                        time={false}
                                                        max={new Date()}
                                                        format={DATE_DISPLAY_FORMAT.DATE}
                                                        inputSize={INPUT_SIZE}
                                                        labelSize={LABEL_SIZE}
                                                        error={errors.FromDate}
                                                        clearFieldEnabled={true}
                                                        disableKeyboardInput={true}
                                                    />
                                                </div>
                                                <div className='advanced-search-cell calendar'>
                                                    <DateInput
                                                        name='ToDate'
                                                        value={values.ToDate}
                                                        placeholder={t(LANGUAGE_KEYS.BLS_PUBLICWORKSPACEAPPLICATION_CONTENT_TITLE_TODATE)}
                                                        onChangeField={onChangeField}
                                                        date={true}
                                                        time={false}
                                                        max={new Date()}
                                                        format={DATE_DISPLAY_FORMAT.DATE}
                                                        inputSize={INPUT_SIZE}
                                                        labelSize={LABEL_SIZE}
                                                        error={errors.ToDate}
                                                        clearFieldEnabled={true}
                                                        disableKeyboardInput={true}
                                                    />
                                                </div>
                                            </div>
                                            <div className='button-center'>
                                                <Button
                                                    color='forward'
                                                    size='sm'
                                                    type='button'
                                                    onClick={() => this.searchDraftInbox(values)}>
                                                    {t(LANGUAGE_KEYS.BLS_PUBLICWORKSPACEAPPLICATION_CONTENT_BUTTON_SEARCH)}</Button>
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </SMARTForm>
                        }

                        {/* Draft Data Grid */}
                        {
                            activeTab === tabs.DRAFTS && searchDraftURL &&
                            <DraftInbox
                                getCustomComponents={this.getCustomComponents}
                                refreshDraftData={this.refreshDraftData}
                                extraGetParams={extraGetParams}
                                triggerDraftTable={this.state.triggerDraftTable}
                                url={searchDraftURL}
                            />
                        }

                        {/* RFA Data Grid */}
                        {activeTab === tabs.REQUIRE_ATTENTION && extraGetParams && extraGetParams.inboxType === INBOX_FILTER.SHOW_REQUIRE_ATTENTION
                            &&
                            <ServerSideDataTable
                                pageSize='5'
                                minFilterChars={MIN_FILTER_CHARS}
                                //data={RFAData}
                                extraGetParams={extraGetParams}
                                url={searchURL}
                                isCustomFilter={true}
                                noResultsMessage={t(LANGUAGE_KEYS.BLS_PUBLICWORKSPACEAPPLICATION_CONTENT_NOTE_NOAPPLICATIONSFOUND)}
                                key={this.state.triggerRfaTable}
                                components={this.getCustomComponents()}
                                columns={
                                    {
                                        ApplicationRefNumber: {
                                            title: t(LANGUAGE_KEYS.BLS_PUBLICWORKSPACEAPPLICATION_CONTENT_TITLE_APPLICATIONREFNUMBER), width: '15%',
                                            DBkey: 'ApplicationRefNumber', setContent: this.setContent, setMobileContent: this.setMobileContent
                                        },
                                        SubmissionNumber: { title: t(LANGUAGE_KEYS.BLS_PUBLICWORKSPACEAPPLICATION_CONTENT_TITLE_SUBMISSIONNUMBER), width: '13%', DBkey: 'SubmissionNumber' },
                                        ApplicationType: { title: t(LANGUAGE_KEYS.BLS_PUBLICWORKSPACEAPPLICATION_CONTENT_TITLE_APPLICATIONTYPE), width: '10%', DBkey: 'ApplicationType', setContent: this.setAppTypeTranslatedContent },
                                        ServiceName: { title: t(LANGUAGE_KEYS.BLS_PUBLICWORKSPACEAPPLICATION_CONTENT_TITLE_SERVICENAME), width: '20%', DBkey: 'ServiceName', setContent: this.setLicenceNameTranslatedContent },
                                        ApplicationStatus: { title: t(LANGUAGE_KEYS.BLS_PUBLICWORKSPACEAPPLICATION_CONTENT_TITLE_APPLICATIONSTATUS), width: '12%', DBkey: 'ApplicationStatus' },
                                        SubmissionDate: { title: t(LANGUAGE_KEYS.BLS_PUBLICWORKSPACEAPPLICATION_CONTENT_TITLE_SUBMISSIONDATE), isDate: true, width: '20%', DBkey: 'SubmissionDate' },
                                        Actions: { title: t(LANGUAGE_KEYS.BLS_PUBLICWORKSPACEAPPLICATION_CONTENT_TITLE_ACTIONS), width: '10%', DBkey: 'Actions' }
                                    }
                                }
                                renderActions={({ RowData }) => (
                                    <DropdownButton
                                        className='btn-action'
                                        buttonLabel='Actions'
                                        disabled={this.getMenuItems(RowData).length > 0 ? false : true}
                                        menuItems={
                                            this.getMenuItems(RowData)
                                        }
                                        onChange={(e) => this.handleSelectChange(e.key, RowData)}
                                        placement='bottomRight'
                                    />
                                )}
                                renderMobileCardActions={this.renderMobileCardActions}
                            />
                        }

                        {/* ALL Data Grid */}
                        {activeTab === tabs.ALL && extraGetParams && extraGetParams.inboxType === INBOX_FILTER.SHOW_ALL &&
                            <ServerSideDataTable
                                pageSize='5'
                                minFilterChars={MIN_FILTER_CHARS}
                                // data={ALLData}
                                extraGetParams={extraGetParams}
                                url={searchURL}
                                isCustomFilter={true}
                                noResultsMessage={t(LANGUAGE_KEYS.BLS_PUBLICWORKSPACEAPPLICATION_CONTENT_NOTE_NOAPPLICATIONSFOUND)}
                                key={this.state.triggerAllTable}
                                components={this.getCustomComponents()}
                                columns={
                                    {
                                        ApplicationRefNumber: {
                                            title: t(LANGUAGE_KEYS.BLS_PUBLICWORKSPACEAPPLICATION_CONTENT_TITLE_APPLICATIONREFNUMBER), width: '15%',
                                            DBkey: 'ApplicationRefNumber', setContent: this.setContent, setMobileContent: this.setMobileContent
                                        },
                                        SubmissionNumber: { title: t(LANGUAGE_KEYS.BLS_PUBLICWORKSPACEAPPLICATION_CONTENT_TITLE_SUBMISSIONNUMBER), width: '13%', DBkey: 'SubmissionNumber' },
                                        ApplicationType: { title: t(LANGUAGE_KEYS.BLS_PUBLICWORKSPACEAPPLICATION_CONTENT_TITLE_APPLICATIONTYPE), width: '10%', DBkey: 'ApplicationType', setContent: this.setAppTypeTranslatedContent },
                                        ServiceName: { title: t(LANGUAGE_KEYS.BLS_PUBLICWORKSPACEAPPLICATION_CONTENT_TITLE_SERVICENAME), width: '20%', DBkey: 'ServiceName', setContent: this.setLicenceNameTranslatedContent },
                                        ApplicationStatus: { title: t(LANGUAGE_KEYS.BLS_PUBLICWORKSPACEAPPLICATION_CONTENT_TITLE_APPLICATIONSTATUS), width: '12%', DBkey: 'ApplicationStatus' },
                                        SubmissionDate: { title: t(LANGUAGE_KEYS.BLS_PUBLICWORKSPACEAPPLICATION_CONTENT_TITLE_SUBMISSIONDATE), isDate: true, width: '20%', DBkey: 'SubmissionDate' },
                                        Actions: { title: t(LANGUAGE_KEYS.BLS_PUBLICWORKSPACEAPPLICATION_CONTENT_TITLE_ACTIONS), width: '10%', DBkey: 'Actions' }
                                    }
                                }
                                renderActions={({ RowData }) => (
                                    <DropdownButton
                                        className='btn-action'
                                        buttonLabel={t(LANGUAGE_KEYS.BLS_PUBLICWORKSPACEAPPLICATION_CONTENT_TITLE_ACTIONS)}
                                        disabled={this.getMenuItems(RowData).length > 0 ? false : true}
                                        menuItems={
                                            this.getMenuItems(RowData)
                                        }
                                        onChange={(e) => this.handleSelectChange(e.key, RowData)}
                                        placement='bottomRight'
                                    />
                                )}
                                renderMobileCardActions={this.renderMobileCardActions}
                                initialSort={{
                                    id: 'SubmissionDate',
                                    sortAscending: false,
                                    isDate: true
                                }}
                            />
                        }
                    </div>
                </div>
            </React.Fragment>
        );
    }
}
export default withTranslation()(withLoader(Applications));