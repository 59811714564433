import React from 'react';
import { withRouter } from 'react-router-dom';
import { compose } from 'recompose';
import { Button } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import { withModalWrapper } from '../../Shared/Modal/ModalWrapper';
import { withLoader } from '../../Shared/Loading';
import { AccordionContainer, Plaintext, SMARTView, ScrollToTop } from '../../Shared/Forms';
import Layout from '../../Layout';
import { navigateTo } from '../../Shared/Actions';
import {DASHBOARD_PATH, LAYOUT_TYPE } from '../../App/AppSettings';
import { LANGUAGE_KEYS } from '../../Shared/Constants/LanguageKeys';
import history from '../../Shared/History';
import { DataTableDemo } from '../../Shared/DataTable';
import { MIN_FILTER_CHARS } from '../../Shared/Constants';
import { getCustomComponents } from '../../Shared/DataTable/BaseTable.js';

const LABEL_SIZE = 6;
const INPUT_SIZE = 6;

const FORM_CONTEXT = 'ViolationDetails';

class ViolationDetails extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            citizen: null
        };
        if (history.location.state) {
            this.state = {
                citizen: history.location.state.citizen
            };
        }
    }
    render() {
        const { t } = this.props;
        const SECTION_NAMES = {
            CITIZEN_OVERVIEW: { title: "Violation Overview", status: true }
        };
        const Citizen = this.state.citizen;
        console.log(Citizen);
        const { ViolationNumber, ViolationType, ViolationStatus, ViolationDescription, ViolationRaisedDate, ResponseDate } = Citizen;
        return (
            <>
                <ScrollToTop />
                <Layout type={LAYOUT_TYPE.FORM} footer={true}>
                    <div className='main-content'>
                        <nav aria-label='breadcrumb' className='breadcrumb-main'>
                            <div className='container'>
                                <ol className='breadcrumb'>
                                    <li className='breadcrumb-item'><a href={DASHBOARD_PATH}>{t(LANGUAGE_KEYS.BLS_PUBLICWORKSPACE_NAVIGATION_MENU_WORKSPACE2)}</a></li>
                                    <li className='breadcrumb-item'><a href={DASHBOARD_PATH}>Violation</a></li>
                                    <li className='breadcrumb-item active' aria-current='page'>{ViolationNumber}</li>
                                </ol>
                                <div className='back-btn'>
                                    <Button
                                        color='header'
                                        className='btn-header'
                                        onClick={() => navigateTo(DASHBOARD_PATH)}>{t(LANGUAGE_KEYS.BLS_PUBLICWORKSPACEAPPLICATION_NAVIGATION_MENU_BACKTOWORKSPACE)}</Button>
                                </div>
                            </div>
                        </nav>
                        <div className='container'>
                            <SMARTView
                                formContext={FORM_CONTEXT}
                                isDummy={false}
                            >
                                {({ toggleSection, toggleAllSections }) => (
                                    <>
                                        <div className='form-collapse detailed-box-form margin'>
                                            <div className='collapse-expand'>
                                                <Button color='toggle' className='toggle-all' onClick={() => toggleAllSections(SECTION_NAMES, true)} >{t(LANGUAGE_KEYS.BLS_PUBLICWORKSPACEAPPLICATION_CONTENT_BUTTON_COLLAPSEALL)}</Button>
                                                <Button color='toggle' className='toggle-all' onClick={() => toggleAllSections(SECTION_NAMES, false)} >{t(LANGUAGE_KEYS.BLS_PUBLICWORKSPACEAPPLICATION_CONTENT_BUTTON_EXPANDALL)}</Button>
                                            </div>
                                            <AccordionContainer
                                                title={SECTION_NAMES.CITIZEN_OVERVIEW.title}
                                                active={SECTION_NAMES.CITIZEN_OVERVIEW.status}
                                                onClick={toggleSection}
                                                isIndividual={true}
                                                sections={SECTION_NAMES}
                                            >
                                                {Citizen &&
                                                    <>
                                                        <div className='card-body'>
                                                            <div className='form-body form-preview'>
                                                                <Plaintext
                                                                    label="Violation Number"
                                                                    name='ViolationNumber'
                                                                    value={ViolationNumber}
                                                                    labelSize={LABEL_SIZE}
                                                                    inputSize={INPUT_SIZE}
                                                                />
                                                                <Plaintext
                                                                    label="Violation Type"
                                                                    name='ViolationType'
                                                                    value={ViolationType}
                                                                    labelSize={LABEL_SIZE}
                                                                    inputSize={INPUT_SIZE}
                                                                />
                                                                <Plaintext
                                                                    label="Violation Status"
                                                                    name='ViolationStatus'
                                                                    value={ViolationStatus}
                                                                    labelSize={LABEL_SIZE}
                                                                    inputSize={INPUT_SIZE}
                                                                />
                                                                <Plaintext
                                                                    label="Violation Description"
                                                                    name='ViolationDescription'
                                                                    value={ViolationDescription}
                                                                    labelSize={LABEL_SIZE}
                                                                    inputSize={INPUT_SIZE}
                                                                />
                                                                <Plaintext
                                                                    label="Violation Raised Date"
                                                                    name='ViolationRaisedDate'
                                                                    value={ViolationRaisedDate}
                                                                    labelSize={LABEL_SIZE}
                                                                    inputSize={INPUT_SIZE}
                                                                />
                                                                <Plaintext
                                                                    label="Response Date"
                                                                    name='ResponseDate'
                                                                    value={ResponseDate}
                                                                    labelSize={LABEL_SIZE}
                                                                    inputSize={INPUT_SIZE}
                                                                />
                                                            </div>
                                                        </div>
                                                    </>
                                                }
                                            </AccordionContainer>
                                            <div className='button-center'>
                                                <Button className='btn' onClick={() => navigateTo(DASHBOARD_PATH)}>{t(LANGUAGE_KEYS.BLS_PUBLICWORKSPACEAPPLICATION_NAVIGATION_MENU_BACKTOWORKSPACE)}</Button>
                                                {/* <Button color='hazard' className='btn' onClick={(e) => e.preventDefault()}>{t(LANGUAGE_KEYS.BLS_PUBLICWORKSPACEAPPLICATION_NAVIGATION_BUTTON_WITHDRAWAPPLICATION)}</Button> */}
                                            </div>
                                        </div>
                                    </>
                                )}
                            </SMARTView>
                        </div>
                    </div>
                </Layout>
            </>
        )
    }
}

export default withTranslation()(withRouter(compose(withLoader, withModalWrapper)(ViolationDetails)));