import React from 'react';
import { withTranslation } from 'react-i18next';
import { Button, Nav, NavItem, NavLink } from 'reactstrap';
import { CORRESPONDENCE_ENQUIRY_PARAMS_URL, DATE_DISPLAY_FORMAT, GET_CORRESPONDENCE_INBOX_COUNT_URL, GET_CORRESPONDENCE_INBOX_SERVER_SIDE_URL, IS_DEMO, VIOLATION_DETAILS_PATH } from '../../App/AppSettings';
import { fetchRequest, getParams, navigateTo } from '../../Shared/Actions';
import { DIRECTIONS, FILTER, MIN_FILTER_CHARS } from '../../Shared/Constants';
import { ERROR, LANGUAGE_KEYS } from '../../Shared/Constants/LanguageKeys';
import { ServerSideDataTable, ServerSideDataTableDemo } from '../../Shared/DataTable';
import { getCustomComponents } from '../../Shared/DataTable/BaseTable.js';
import { DateInput, SelectList, SMARTForm, Text, toastError } from '../../Shared/Forms';
import { arrayToSelectListOptions, arrayToTranslatedDeliveryChannelSelectListOptions } from '../../Shared/Forms/FormUtils.js';
import { withLoader } from '../../Shared/Loading';
import { getLangKey, getLicenceTypeNameTranslationKey } from '../DisplayComponents/DisplayUtils';

const FORM_CONTEXT = "Applications";
const LABEL_SIZE = 0;
const INPUT_SIZE = 12;

//this const must be the same as the controller
const INBOX_FILTER = {
    SHOW_ALL: "ALL",
    SHOW_UNREAD: "UNREAD"
}

//data table server side url
const CORRESPONDENCE_INBOX_URL = GET_CORRESPONDENCE_INBOX_SERVER_SIDE_URL;
const CORRESPONDENCE_INBOX_COUNT_URL = GET_CORRESPONDENCE_INBOX_COUNT_URL;

// Application Enquiry Search
const PARAMS_URL = CORRESPONDENCE_ENQUIRY_PARAMS_URL;

const ACTIONS = {
    RESPOND_TO_ACTION: "Respond to Action",
    WITHDRAW: "Withdraw"
}

const FORM_INITIAL_VALUES = {
    CorrespondenceTitle: '',
    Agency: '',
    DeliveryChannel: '',
    FromDate: '',
    ToDate: '',
};

const tabs = {
    UNREAD: `UNREAD`,
    ALL: `ALL`,
    VIOLATION: "VIOLATION"
}

class Correspondences extends React.Component {
    constructor(props) {
        super(props);
        const { t } = this.props;
        this.state = {
            activeTab: tabs.UNREAD,
            values: FORM_INITIAL_VALUES,
            agencyNameOptions: [],
            deliveryChannelOptions: [],
            filter: "",
            showAdvancedSearch: false,
            tabNames: {
                UNREAD: t(LANGUAGE_KEYS.BLS_PUBLICWORKSPACECORRESPONDENCE_CONTENT_MENU_UNREAD),
                ALL: t(LANGUAGE_KEYS.BLS_PUBLICWORKSPACECORRESPONDENCE_CONTENT_MENU_ALL),
                VIOLATION: "Violations"
            }
        }
    }

    componentDidMount = async () => {
        const { loader } = this.props;

        // Get data
        // Start loading
        loader.start();

        const countData = await this.getCountData(CORRESPONDENCE_INBOX_COUNT_URL);

        // Done loading
        loader.done();

        this.setState({
            correspondenceAllCount: countData.correspondenceAllCount,
            correspondenceUnreadCount: countData.correspondenceUnreadCount,
            searchURL: CORRESPONDENCE_INBOX_URL,
            triggerUnreadTable: Math.random()
        })

        this.getCorrespondenceFilterParams(tabs.UNREAD);
        this.getExtraParams();
    }

    getCountData = async (url) => {
       
        const { t } = this.props;
        const response = await fetchRequest(url, getParams(), IS_DEMO);
        const { IsSuccess, CorrespondenceAllCount, CorrespondenceUnreadCount } = response.body;

        // If success, load retrieved data into state
        if (IsSuccess) {
            return {
                correspondenceAllCount: CorrespondenceAllCount,
                correspondenceUnreadCount: CorrespondenceUnreadCount
            };
        }
        else {
            toastError(t(ERROR.LIST_RETRIEVAL_FAIL));

            return {
                correspondenceAllCount: 0,
                correspondenceUnreadCount: 0
            };

        }
    }

    getCorrespondenceFilterParams = async (activeTab) => {
        const inboxTab = (activeTab === tabs.UNREAD) ? INBOX_FILTER.SHOW_UNREAD : INBOX_FILTER.SHOW_ALL;
        const { t } = this.props;
        const response = await fetchRequest(PARAMS_URL + inboxTab, getParams(), IS_DEMO);
        const { IsSuccess, Data, Messages } = response.body;

        if (IsSuccess) {
            const { CorrespondenceAgency, DeliveryChannel } = Data;
            let agencyNameOptions = [];
            let deliveryChannelOptions = [];

            // Format filter params into SelectList format
            arrayToSelectListOptions(CorrespondenceAgency, agencyNameOptions);
            arrayToTranslatedDeliveryChannelSelectListOptions(DeliveryChannel, deliveryChannelOptions, this.props.t);

            this.setState({
                agencyNameOptions: agencyNameOptions,
                deliveryChannelOptions: deliveryChannelOptions,
            });
        }
        else {
            toastError(t(getLangKey(ERROR.BACKEND_ERROR_MESSAGE, Messages)), Messages);
        }
    }

    setContent = (rowData) => {
        return <div className="dashboard-correspondences-link" onClick={() => this.redirectToDetailsPage(rowData)}>{rowData.Title}</div>;
    }

    setMobileContent = (rowData) => {
        const { t } = this.props;
        return (
            <tr key={"CorrespondenceTitle"}>
                <td width="40%" className="card-table-td card-table-title"><b>{t(LANGUAGE_KEYS.BLS_PUBLICWORKSPACECORRESPONDENCE_TABLE_TITLE_CORRESPONDENCETITLE)}</b>:</td>
                <td width="60%" className="card-table-td card-table-data">{this.setContent(rowData)}</td>
            </tr>
        );
    }

    setChannelTranslatedContent = (rowData) => {
        const { t } = this.props;
        return (
            <>{t(getLangKey(LANGUAGE_KEYS.BLS_CODE_CORRESPONDENCETYPE_TITLE_KEY, rowData.DeliveryChannelCodeName))}</>
        );
    }

    setLicenceForTable = (rowData) => {
        const { t } = this.props;
        const licenceList = [];
        rowData.LicenceTypes.forEach(lt => {
            const licenceTypeNameTranslationKey = getLicenceTypeNameTranslationKey(lt.LicenceName);
            licenceList.push(<div key={lt.AgencyName + lt.LicenceName + "Licence"} className="correspondence-licence correspondence-cell">{t(getLangKey(LANGUAGE_KEYS.BLS_SCOPE_LICENCETYPE_LICENCETYPENAME_KEY, licenceTypeNameTranslationKey, lt.LicenceTypeId))}</div>)
        })
        if (licenceList.length === 0) {
            licenceList.push(<div key={"Licence"}>-</div>)
        }
        return licenceList;
    }

    setAgencyForTable = (rowData) => {
        let agencyList = []
        rowData.LicenceTypes.forEach(lt =>
            agencyList.push(<div key={lt.AgencyName + lt.LicenceName + "Agency"} className="correspondence-agency correspondence-cell">{lt.AgencyName}</div>)
        );
        if (agencyList.length === 0) {
            agencyList.push(<div key={"Agency"}>-</div>)
        }
        return agencyList;
    }

    handleSelectChange = (key, rowData) => {
        if (key === ACTIONS.RESPOND_TO_ACTION) {
            return this.redirectToRFAPage(rowData);
        }
    };

    redirectToDetailsPage = (rowData) => (
        navigateTo("/correspondenceDetails", { ID: rowData.CorrespondenceId })
    )

    toggleTab = (activeTab) => {
        this.setState({
            activeTab: activeTab,
            extraGetParams: { inboxType: activeTab === tabs.UNREAD ? INBOX_FILTER.SHOW_UNREAD : INBOX_FILTER.SHOW_ALL },
        }, () => {
            if (activeTab === tabs.DRAFTS) {
                this.getApplicationDraftFilterParams();
            } else {
                this.getCorrespondenceFilterParams(activeTab);
            }
        })
    }

    getCustomComponents = () => {
        let components = getCustomComponents();
        components.Filter = this.Filter;

        return components;
    }

    onFilterChange = (filterName, value, setFilter) => {
        this.setState({
            [filterName]: value
        });
        const filterFields = {
            [FILTER]: value
        };
        setFilter(filterFields);
    }

    Filter = ({ setFilter }) => {
        const { t } = this.props;
        return (
            <div className={`${this.state.showAdvancedSearch ? "dashboard-search search-hidden" : "dashboard-search"}`}>
                <div className="search">
                    <div className="search-box">
                        <div className="search-cell search-head">{this.state.tabNames[this.state.activeTab]}</div>
                        <div className="search-cell">
                            <div className="input-group">
                                <input
                                    dir={DIRECTIONS.AUTO}
                                    type="text"
                                    name="filter"
                                    defaultValue={this.state.filter}
                                    className="form-control"
                                    placeholder={t(LANGUAGE_KEYS.BLS_PUBLICWORKSPACECORRESPONDENCE_CONTENT_PLACEHOLDER_CORRESPONDENCESEARCHBOX)}
                                    onChange={e => this.onFilterChange("filter", e.target.value, setFilter)}
                                />
                                <div className="input-group-append">
                                    <button className="btn btn-outline-secondary" type="button"><i className="fal fa-search"></i></button>
                                </div>
                            </div>
                        </div>
                        <div className="search-cell advanced-search"><span onClick={this.toggleAdvancedSearch}>{t(LANGUAGE_KEYS.BLS_PUBLICWORKSPACECORRESPONDENCE_CONTENT_TITLE_ADVANCEDSEARCH)}</span></div>
                    </div>
                </div>
            </div>
        );
    }

    toggleAdvancedSearch = () => {
        const { activeTab } = this.state;

        if (activeTab === tabs.UNREAD) {
            this.setState((prevState) => {
                return {
                    showAdvancedSearch: !prevState.showAdvancedSearch,
                    extraGetParams: { inboxType: INBOX_FILTER.SHOW_UNREAD },
                    triggerUnreadTable: Math.random()
                }
            })
        }
        else if (activeTab === tabs.ALL) {
            this.setState((prevState) => {
                return {
                    showAdvancedSearch: !prevState.showAdvancedSearch,
                    extraGetParams: { inboxType: INBOX_FILTER.SHOW_ALL },
                    triggerAllTable: Math.random()
                }
            })
        }
    }

    getExtraParams = () => {
        const { activeTab } = this.state;

        if (activeTab === tabs.UNREAD) {
            this.setState({
                extraGetParams: { inboxType: INBOX_FILTER.SHOW_UNREAD }
            })
        }
        else if (activeTab === tabs.ALL) {
            this.setState({
                extraGetParams: { inboxType: INBOX_FILTER.SHOW_ALL }
            })
        }

    }

    searchCorrespondencesInbox = (values) => {
        const { activeTab } = this.state;

        if (activeTab === tabs.UNREAD) {
            this.setState({
                extraGetParams: Object.assign({}, values, { inboxType: INBOX_FILTER.SHOW_UNREAD }),
                triggerUnreadTable: Math.random()
            })
        }
        else if (activeTab === tabs.ALL) {
            this.setState({
                extraGetParams: Object.assign({}, values, { inboxType: INBOX_FILTER.SHOW_ALL }),
                triggerAllTable: Math.random()
            })
        }

    }

    setViolationContent = (rowData) => {
        return <div className='dashboard-application-link' onClick={() => this.redirectToViolationPage(rowData)}>{rowData.ViolationNumber}</div>;
    }

    redirectToViolationPage = (rowData) => (
        navigateTo(VIOLATION_DETAILS_PATH, { citizen: rowData })
    )

    setMViolationobileContent = (rowData) => {
        //const { t } = this.props;
        return (
            <tr key={'AppRefNum'}>
                <td width='40%' className='card-table-td card-table-title'><b>Violation Number</b>:</td>
                <td width='60%' className='card-table-td card-table-data'>{this.setViolationContent(rowData)}</td>
            </tr>
        );
    }

    render() {
        const { activeTab, showAdvancedSearch, searchURL, extraGetParams, correspondenceUnreadCount, correspondenceAllCount } = this.state;
        const { t } = this.props;
        var inboxTab = (activeTab === tabs.UNREAD) ? INBOX_FILTER.SHOW_UNREAD : INBOX_FILTER.SHOW_ALL;

        return (
            <React.Fragment>
                <div id="Correspondences">
                    <div className="inner-tabs">
                        <Nav tabs className="tab-nav-tabs">
                            <NavItem key={0} className="tab-nav-item">
                                <NavLink
                                    className={"tab-header tab-nav-link"}
                                    active={(activeTab === tabs.UNREAD)}
                                    id="Require_Attention-tab"
                                    onClick={() => this.toggleTab(tabs.UNREAD)}
                                >
                                    {t(LANGUAGE_KEYS.BLS_PUBLICWORKSPACECORRESPONDENCE_CONTENT_MENU_UNREAD)}<span>{correspondenceUnreadCount}</span>
                                </NavLink>
                            </NavItem>

                            <NavItem key={1} className="tab-nav-item">
                                <NavLink
                                    className={"tab-header tab-nav-link"}
                                    active={(activeTab === tabs.ALL)}
                                    id="Applications_All-tab"
                                    onClick={() => this.toggleTab(tabs.ALL)}
                                >
                                    {t(LANGUAGE_KEYS.BLS_PUBLICWORKSPACECORRESPONDENCE_CONTENT_MENU_ALL)}<span>{correspondenceAllCount}</span>
                                </NavLink>
                            </NavItem>
                            <NavItem key={2} className="tab-nav-item">
                            <NavLink
                                className={"tab-header tab-nav-link"}
                                active={(activeTab === tabs.VIOLATION)}
                                id="Correspondences_All-tab"
                                onClick={() => 
                                    this.toggleTab(tabs.VIOLATION)}
                            >
                                {this.state.tabNames.VIOLATION}<span>3</span>
                            </NavLink>
                        </NavItem>
                        </Nav>

                        {showAdvancedSearch &&
                            <SMARTForm
                                key={inboxTab}
                                formContext={FORM_CONTEXT}
                                validateOnSubmit={true}
                                isDummy={IS_DEMO}
                            >
                                {({ values, errors, onChange, onChangeField }) => (
                                    <div className="dashboard-search">
                                        <div className={`${this.state.showAdvancedSearch ? "advanced-search-container search-shown" : "advanced-search-container search-hidden"}`}>
                                            <div className="advanced-serach-header">
                                                {t(LANGUAGE_KEYS.BLS_PUBLICWORKSPACECORRESPONDENCE_CONTENT_TITLE_ADVANCEDSEARCH)}
                                                <span className="advanced-search-close">
                                                    <i className="fal fa-times" onClick={this.toggleAdvancedSearch}>                                                      </i>
                                                </span>
                                            </div>
                                            <div className="advanced-search-fields">
                                                <div className="advanced-search-cell ">
                                                    <Text
                                                        name="CorrespondenceTitle"
                                                        value={values.CorrespondenceTitle}
                                                        placeholder={t(LANGUAGE_KEYS.BLS_PUBLICWORKSPACECORRESPONDENCE_TABLE_TITLE_CORRESPONDENCETITLE)}
                                                        onChange={onChange}
                                                        minLength={0}
                                                        maxLength={140}
                                                        inputSize={INPUT_SIZE}
                                                        labelSize={LABEL_SIZE}
                                                        error={errors.CorrespondenceTitle}
                                                    />
                                                </div>
                                                <div className="advanced-search-cell ">
                                                    <SelectList
                                                        name="Agency"
                                                        value={values.Agency}
                                                        options={this.state.agencyNameOptions}
                                                        onChangeField={onChangeField}
                                                        placeholder={t(LANGUAGE_KEYS.BLS_PUBLICWORKSPACECORRESPONDENCE_TABLE_TITLE_AGENCY)}
                                                        isMulti={false}
                                                        isClearable={true}
                                                        inputSize={INPUT_SIZE}
                                                        labelSize={LABEL_SIZE}
                                                        error={errors.Agency}
                                                    />
                                                </div>
                                                <div className="advanced-search-cell ">
                                                    <SelectList
                                                        name="DeliveryChannelId"
                                                        value={values.DeliveryChannelId}
                                                        options={this.state.deliveryChannelOptions}
                                                        onChangeField={onChangeField}
                                                        placeholder={t(LANGUAGE_KEYS.BLS_PUBLICWORKSPACECORRESPONDENCE_TABLE_TITLE_DELIVERYCHANNEL)}
                                                        isMulti={false}
                                                        isClearable={true}
                                                        inputSize={INPUT_SIZE}
                                                        labelSize={LABEL_SIZE}
                                                        error={errors.DeliveryChannelId}
                                                    />
                                                </div>
                                                <div className="advanced-search-cell calendar">
                                                    <DateInput
                                                        name="FromDate"
                                                        value={values.FromDate}
                                                        placeholder={t(LANGUAGE_KEYS.BLS_PUBLICWORKSPACECORRESPONDENCE_CONTENT_FIELD_FROMDATE)}
                                                        onChangeField={onChangeField}
                                                        date={true}
                                                        time={false}
                                                        max={new Date()}
                                                        format={DATE_DISPLAY_FORMAT.DATE}
                                                        inputSize={INPUT_SIZE}
                                                        labelSize={LABEL_SIZE}
                                                        error={errors.FromDate}
                                                        clearFieldEnabled={true}
                                                        disableKeyboardInput={true}
                                                    />
                                                </div>
                                                <div className="advanced-search-cell calendar">
                                                    <DateInput
                                                        name="ToDate"
                                                        value={values.ToDate}
                                                        placeholder={t(LANGUAGE_KEYS.BLS_PUBLICWORKSPACECORRESPONDENCE_CONTENT_FIELD_TODATE)}
                                                        onChangeField={onChangeField}
                                                        date={true}
                                                        time={false}
                                                        max={new Date()}
                                                        format={DATE_DISPLAY_FORMAT.DATE}
                                                        inputSize={INPUT_SIZE}
                                                        labelSize={LABEL_SIZE}
                                                        error={errors.ToDate}
                                                        clearFieldEnabled={true}
                                                        disableKeyboardInput={true}
                                                    />
                                                </div>
                                            </div>
                                            <div className="button-center">
                                                <Button
                                                    color="forward"
                                                    size="sm"
                                                    type="button"
                                                    onClick={() => this.searchCorrespondencesInbox(values)}>
                                                    {t(LANGUAGE_KEYS.BLS_PUBLICWORKSPACECORRESPONDENCE_CONTENT_BUTTON_SEARCH)}</Button>
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </SMARTForm>
                        }

                        {/* Data Grid  */}
                        {activeTab === tabs.UNREAD && extraGetParams &&
                            <ServerSideDataTable
                                pageSize="5"
                                minFilterChars={MIN_FILTER_CHARS}
                                extraGetParams={extraGetParams}
                                url={searchURL}
                                key={this.state.triggerUnreadTable}
                                isCustomFilter={true}
                                noResultsMessage={t(LANGUAGE_KEYS.BLS_PUBLICWORKSPACECORRESPONDENCE_MESSAGE_NOTE_NOCORRESPONDENCEFOUND)}
                                components={this.getCustomComponents()}
                                columns={
                                    {
                                        Title: { title: t(LANGUAGE_KEYS.BLS_PUBLICWORKSPACECORRESPONDENCE_TABLE_TITLE_CORRESPONDENCETITLE), width: '35%', DBkey: 'Title', setContent: this.setContent, setMobileContent: this.setMobileContent },
                                        DeliveryChannel: { title: t(LANGUAGE_KEYS.BLS_PUBLICWORKSPACECORRESPONDENCE_TABLE_TITLE_DELIVERYCHANNEL), width: '10%', DBkey: 'DeliveryChannel', setContent: this.setChannelTranslatedContent },
                                        DeliveryDate: { title: t(LANGUAGE_KEYS.BLS_PUBLICWORKSPACECORRESPONDENCE_TABLE_TITLE_DELIVERYDATE), isDate: true, width: '15%', DBkey: 'DeliveryDate' },
                                        Service: { title: t(LANGUAGE_KEYS.BLS_PUBLICWORKSPACECORRESPONDENCE_TABLE_TITLE_SERVICE), width: '20%', DBkey: 'Service', setContent: this.setLicenceForTable },
                                        AgencyNames: { title: t(LANGUAGE_KEYS.BLS_PUBLICWORKSPACECORRESPONDENCE_TABLE_TITLE_AGENCY), width: '10%', DBkey: 'AgencyNames', setContent: this.setAgencyForTable },
                                    }
                                }
                            />
                        }

                        {activeTab === tabs.ALL && extraGetParams &&
                            <ServerSideDataTable
                                pageSize="5"
                                minFilterChars={MIN_FILTER_CHARS}
                                extraGetParams={extraGetParams}
                                url={searchURL}
                                key={this.state.triggerAllTable}
                                isCustomFilter={true}
                                noResultsMessage={t(LANGUAGE_KEYS.BLS_PUBLICWORKSPACECORRESPONDENCE_MESSAGE_NOTE_NOCORRESPONDENCEFOUND)}
                                components={this.getCustomComponents()}
                                columns={
                                    {
                                        Title: { title: t(LANGUAGE_KEYS.BLS_PUBLICWORKSPACECORRESPONDENCE_TABLE_TITLE_CORRESPONDENCETITLE), width: '35%', DBkey: 'Title', setContent: this.setContent },
                                        DeliveryChannel: { title: t(LANGUAGE_KEYS.BLS_PUBLICWORKSPACECORRESPONDENCE_TABLE_TITLE_DELIVERYCHANNEL), width: '10%', DBkey: 'DeliveryChannel', setContent: this.setChannelTranslatedContent },
                                        DeliveryDate: { title: t(LANGUAGE_KEYS.BLS_PUBLICWORKSPACECORRESPONDENCE_TABLE_TITLE_DELIVERYDATE), isDate: true, width: '15%', DBkey: 'DeliveryDate' },
                                        LicenceNames: { title: t(LANGUAGE_KEYS.BLS_PUBLICWORKSPACECORRESPONDENCE_TABLE_TITLE_SERVICE), width: '20%', DBkey: 'LicenceNames', setContent: this.setLicenceForTable },
                                        AgencyNames: { title: t(LANGUAGE_KEYS.BLS_PUBLICWORKSPACECORRESPONDENCE_TABLE_TITLE_AGENCY), width: '10%', DBkey: 'AgencyNames', setContent: this.setAgencyForTable },
                                    }
                                }

                            />
                        }

                        {activeTab === tabs.VIOLATION && extraGetParams &&
                        <ServerSideDataTableDemo
                            pageSize="5"
                            minFilterChars={MIN_FILTER_CHARS}
                            extraGetParams={extraGetParams}
                            dataType="violation"
                            //url={searchRenewURL}
                            //noResultsMessage={t(LANGUAGE_KEYS.BLS_PUBLICWORKSPACELICENCE_MESSAGE_NOTE_NOLICENCESFOUND)}
                            noResultsMessage={"No Business entity found"}
                            isCustomFilter={true}
                            key={this.state.triggerBusinessTable}
                            components={this.getCustomComponents()}
                            columns={
                                {
                                    ViolationNumber: { title: "Violation Number", width: '15%', DBkey: 'ViolationNumber', setContent: this.setViolationContent, setMobileContent: this.setViolationContent },
                                    ViolationType: { title: "Violation Type", width: '20%', DBkey: 'ViolationType' },
                                    ViolationStatus: { title: "Violation Status", width: '15%', DBkey: 'ViolationStatus' },
                                    ViolationDescription: { title: "Violation Description", width: '20%', DBkey: 'ViolationDescription' },
                                    ViolationRaisedDate: { title: "Violation Raised Date", width: '15%', DBkey: 'ViolationRaisedDate', isDate: true },
                                    ResponseDate: { title: "Response Date", width: '15%', DBkey: 'ResponseDate', isDate:true },
                                }
                            }
                            renderActions={({ RowData }) => this.setActionOptions(RowData, tabs.BUSINESS_ENTITES)}
                            renderMobileCardActions={(RowData) => this.renderMobileCardActions(RowData, tabs.BUSINESS_ENTITES)}
                        />
                    }
                    </div>
                </div>
            </React.Fragment>
        )
    }
}

export default withTranslation()(withLoader(Correspondences));
