import React from 'react';
import { withRouter } from 'react-router-dom';
import { compose } from 'recompose';
import { Button } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import { withModalWrapper } from '../../Shared/Modal/ModalWrapper';
import { withLoader } from '../../Shared/Loading';
import { AccordionContainer, Plaintext, SMARTView, ScrollToTop } from '../../Shared/Forms';
import Layout from '../../Layout';
import { navigateTo } from '../../Shared/Actions';
import {DASHBOARD_PATH, LAYOUT_TYPE } from '../../App/AppSettings';
import { LANGUAGE_KEYS } from '../../Shared/Constants/LanguageKeys';
import history from '../../Shared/History';
import { DataTableDemo } from '../../Shared/DataTable';
import { MIN_FILTER_CHARS } from '../../Shared/Constants';
import { getCustomComponents } from '../../Shared/DataTable/BaseTable.js';

const LABEL_SIZE = 6;
const INPUT_SIZE = 6;

const FORM_CONTEXT = 'CitizenDetails';

class CitizenDetails extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            citizen: null
        };
        if (history.location.state) {
            this.state = {
                citizen: history.location.state.citizen
            };
        }
    }
    render() {
        const { t } = this.props;
        const SECTION_NAMES = {
            CITIZEN_OVERVIEW: { title: "User Overview", status: true },
            CITIZEN_APPLICATION_HISTORY: { title: "Service Application Hitory", status: true }
        };
        const Citizen = this.state.citizen;
        console.log(Citizen);
        const { Username, FirstName, MiddleName, LastName } = Citizen;
        return (
            <>
                <ScrollToTop />
                <Layout type={LAYOUT_TYPE.FORM} footer={true}>
                    <div className='main-content'>
                        <nav aria-label='breadcrumb' className='breadcrumb-main'>
                            <div className='container'>
                                <ol className='breadcrumb'>
                                    <li className='breadcrumb-item'><a href={DASHBOARD_PATH}>{t(LANGUAGE_KEYS.BLS_PUBLICWORKSPACE_NAVIGATION_MENU_WORKSPACE2)}</a></li>
                                    <li className='breadcrumb-item'><a href={DASHBOARD_PATH}>User</a></li>
                                    <li className='breadcrumb-item active' aria-current='page'>{Username}</li>
                                </ol>
                                <div className='back-btn'>
                                    <Button
                                        color='header'
                                        className='btn-header'
                                        onClick={() => navigateTo(DASHBOARD_PATH)}>{t(LANGUAGE_KEYS.BLS_PUBLICWORKSPACEAPPLICATION_NAVIGATION_MENU_BACKTOWORKSPACE)}</Button>
                                </div>
                            </div>
                        </nav>
                        <div className='container'>
                            <SMARTView
                                formContext={FORM_CONTEXT}
                                isDummy={false}
                            >
                                {({ toggleSection, toggleAllSections }) => (
                                    <>
                                        <div className='form-collapse detailed-box-form margin'>
                                            <div className='collapse-expand'>
                                                <Button color='toggle' className='toggle-all' onClick={() => toggleAllSections(SECTION_NAMES, true)} >{t(LANGUAGE_KEYS.BLS_PUBLICWORKSPACEAPPLICATION_CONTENT_BUTTON_COLLAPSEALL)}</Button>
                                                <Button color='toggle' className='toggle-all' onClick={() => toggleAllSections(SECTION_NAMES, false)} >{t(LANGUAGE_KEYS.BLS_PUBLICWORKSPACEAPPLICATION_CONTENT_BUTTON_EXPANDALL)}</Button>
                                            </div>
                                            <AccordionContainer
                                                title="Account Info"
                                                active={SECTION_NAMES.CITIZEN_OVERVIEW.status}
                                                onClick={toggleSection}
                                                isIndividual={true}
                                                sections={SECTION_NAMES}
                                            >
                                                {Citizen &&
                                                    <>
                                                        <div className='card-body'>
                                                            <div className='form-body form-preview'>
                                                                <Plaintext
                                                                    label="User Type"
                                                                    name='UserType'
                                                                    value="External"
                                                                    labelSize={LABEL_SIZE}
                                                                    inputSize={INPUT_SIZE}
                                                                />
                                                                <Plaintext
                                                                    label="Username"
                                                                    name='Username'
                                                                    value={Username}
                                                                    labelSize={LABEL_SIZE}
                                                                    inputSize={INPUT_SIZE}
                                                                />
                                                            </div>
                                                        </div>
                                                    </>
                                                }
                                            </AccordionContainer>
                                            <AccordionContainer
                                                title="Personal Details"
                                                active="true"
                                                onClick={toggleSection}
                                                isIndividual={true}
                                                sections={SECTION_NAMES}>
                                                    <div className='card-body'>
                                                        <div className='form-body form-preview'>
                                                            <Plaintext
                                                                label="Salutation"
                                                                name='Salutation'
                                                                value="Mr"
                                                                labelSize={LABEL_SIZE}
                                                                inputSize={INPUT_SIZE}
                                                            />
                                                            <Plaintext
                                                                label="First Name"
                                                                name='FirstName'
                                                                value={FirstName}
                                                                labelSize={LABEL_SIZE}
                                                                inputSize={INPUT_SIZE}
                                                            />
                                                            <Plaintext
                                                                label="Middle Name"
                                                                name='MiddleName'
                                                                value={MiddleName}
                                                                labelSize={LABEL_SIZE}
                                                                inputSize={INPUT_SIZE}
                                                            />
                                                            <Plaintext
                                                                label="Last Name"
                                                                name='LastName'
                                                                value={LastName}
                                                                labelSize={LABEL_SIZE}
                                                                inputSize={INPUT_SIZE}
                                                            />
                                                            <Plaintext
                                                                label="Gender"
                                                                name='Gender'
                                                                value="Male"
                                                                labelSize={LABEL_SIZE}
                                                                inputSize={INPUT_SIZE}
                                                            />
                                                            <Plaintext
                                                                label="Date of Birth"
                                                                name='DOB'
                                                                value="26-Jul-1991"
                                                                labelSize={LABEL_SIZE}
                                                                inputSize={INPUT_SIZE}
                                                            />
                                                            <Plaintext
                                                                label="Nationality"
                                                                name='Nationality'
                                                                value="China"
                                                                labelSize={LABEL_SIZE}
                                                                inputSize={INPUT_SIZE}
                                                            />
                                                            <Plaintext
                                                                label="Passport"
                                                                name='Passport'
                                                                value="S323523"
                                                                labelSize={LABEL_SIZE}
                                                                inputSize={INPUT_SIZE}
                                                            />
                                                        </div>
                                                    </div> 
                                            </AccordionContainer>
                                            <AccordionContainer
                                                title="Contact Info"
                                                active="true"
                                                onClick={toggleSection}
                                                isIndividual={true}
                                                sections={SECTION_NAMES}>
                                                    <div className='card-body'>
                                                        <div className='form-body form-preview'>
                                                            <Plaintext
                                                                label="Primary Email Address"
                                                                name='Email1'
                                                                value="zierui@starmaker.email"
                                                                labelSize={LABEL_SIZE}
                                                                inputSize={INPUT_SIZE}
                                                            />
                                                            <Plaintext
                                                                label="Primary Contact"
                                                                name='Contact'
                                                                value="Mobile Number"
                                                                labelSize={LABEL_SIZE}
                                                                inputSize={INPUT_SIZE}
                                                            />
                                                            <Plaintext
                                                                label="Mobile Number"
                                                                name='Mobile'
                                                                value="+65 93842948"
                                                                labelSize={LABEL_SIZE}
                                                                inputSize={INPUT_SIZE}
                                                            />
                                                        </div>
                                                    </div> 
                                            </AccordionContainer>
                                            <AccordionContainer
                                                title="Address Details"
                                                active="true"
                                                onClick={toggleSection}
                                                isIndividual={true}
                                                sections={SECTION_NAMES}>
                                                    <div className='card-body'>
                                                        <div className='form-body form-preview'>
                                                            <Plaintext
                                                                label="Address Type"
                                                                name='Email1'
                                                                value="Local"
                                                                labelSize={LABEL_SIZE}
                                                                inputSize={INPUT_SIZE}
                                                            />
                                                            <Plaintext
                                                                label="ZIP / Postal Code"
                                                                name='Contact'
                                                                value="498984"
                                                                labelSize={LABEL_SIZE}
                                                                inputSize={INPUT_SIZE}
                                                            />
                                                            <Plaintext
                                                                label="Block / House No."
                                                                name='Mobile'
                                                                value="285B"
                                                                labelSize={LABEL_SIZE}
                                                                inputSize={INPUT_SIZE}
                                                            />
                                                            <Plaintext
                                                                label="Street Name"
                                                                name='Mobile'
                                                                value="Jurong East"
                                                                labelSize={LABEL_SIZE}
                                                                inputSize={INPUT_SIZE}
                                                            />
                                                            <Plaintext
                                                                label="Level"
                                                                name='Mobile'
                                                                value="12"
                                                                labelSize={LABEL_SIZE}
                                                                inputSize={INPUT_SIZE}
                                                            />
                                                            <Plaintext
                                                                label="Unit No."
                                                                name='Mobile'
                                                                value="52"
                                                                labelSize={LABEL_SIZE}
                                                                inputSize={INPUT_SIZE}
                                                            />
                                                        </div>
                                                    </div> 
                                            </AccordionContainer>
                                            <AccordionContainer
                                                title="Assign Global User Groups"
                                                active="true"
                                                onClick={toggleSection}
                                                isIndividual={true}
                                                sections={SECTION_NAMES}>
                                                    <div className='card-body'>
                                                        <div className='form-body form-preview'>
                                                        <DataTableDemo
                                                            pageSize='5'
                                                            minFilterChars={MIN_FILTER_CHARS}
                                                            dataType="citizen"
                                                            noResultsMessage={t(LANGUAGE_KEYS.BLS_PUBLICWORKSPACELICENCE_MESSAGE_NOTE_NOLICENCEAPPHISTORYFOUND)}
                                                            components={getCustomComponents()}
                                                            columns={
                                                                {
                                                                    Number: { title: "No.", width: '10%', DBkey: 'Number' },
                                                                    Application: { title: "Application", width: '20%', DBkey: 'Application' },
                                                                    GroupName: { title: "Group Name", width: '20%', DBkey: 'GroupName' },
                                                                    Description: { title: "Description", width: '20%', DBkey: 'Description' },
                                                                    EffectStart: { title: "Effective Start Date", width: '20%', DBkey: 'EffectStart' },
                                                                    EffectEnd: { title: "Effective End Date", width: '20%', DBkey: 'EffectEnd' },
                                                                }
                                                            }
                                                        />
                                                        </div>
                                                    </div> 
                                            </AccordionContainer>
                                            <AccordionContainer
                                                title="Assign User Groups"
                                                active="true"
                                                onClick={toggleSection}
                                                isIndividual={true}
                                                sections={SECTION_NAMES}>
                                                    <div className='card-body'>
                                                        <div className='form-body form-preview'>
                                                        <DataTableDemo
                                                            pageSize='5'
                                                            minFilterChars={MIN_FILTER_CHARS}
                                                            dataType="citizen"
                                                            noResultsMessage={t(LANGUAGE_KEYS.BLS_PUBLICWORKSPACELICENCE_MESSAGE_NOTE_NOLICENCEAPPHISTORYFOUND)}
                                                            components={getCustomComponents()}
                                                            columns={
                                                                {
                                                                    Number: { title: "No.", width: '10%', DBkey: 'Number' },
                                                                    Organization: { title: "Organization", width: '20%', DBkey: 'Application' },
                                                                    GroupName: { title: "Group Name", width: '20%', DBkey: 'GroupName' },
                                                                    Description: { title: "Description", width: '20%', DBkey: 'Description' },
                                                                    EffectStart: { title: "Effective Start Date", width: '20%', DBkey: 'EffectStart' },
                                                                    EffectEnd: { title: "Effective End Date", width: '20%', DBkey: 'EffectEnd' },
                                                                }
                                                            }
                                                        />
                                                        </div>
                                                    </div> 
                                            </AccordionContainer>
                                            <div className='button-center'>
                                                <Button className='btn' onClick={() => navigateTo(DASHBOARD_PATH)}>{t(LANGUAGE_KEYS.BLS_PUBLICWORKSPACEAPPLICATION_NAVIGATION_MENU_BACKTOWORKSPACE)}</Button>
                                                {/* <Button color='hazard' className='btn' onClick={(e) => e.preventDefault()}>{t(LANGUAGE_KEYS.BLS_PUBLICWORKSPACEAPPLICATION_NAVIGATION_BUTTON_WITHDRAWAPPLICATION)}</Button> */}
                                            </div>
                                        </div>
                                    </>
                                )}
                            </SMARTView>
                        </div>
                    </div>
                </Layout>
            </>
        )
    }
}

export default withTranslation()(withRouter(compose(withLoader, withModalWrapper)(CitizenDetails)));