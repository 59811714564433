import React, { useState } from "react";
import { FaStar } from "react-icons/fa";

const Rating = ({ maxRating = 5, onRatingChange }) => {
	const [hover, setHover] = useState(null);
	const [rating, setRating] = useState(0);

	const handleClick = (ratingValue) => {
		setRating(ratingValue);
		if (onRatingChange) {
			onRatingChange(ratingValue);
		}
	};

	return (
		<div style={{ display: "flex", gap: "5px" }}>
			{Array.from({ length: maxRating }, (_, i) => i + 1).map((star) => (
				<button
					key={star}
					type="button"
					onClick={() => handleClick(star)}
					onMouseEnter={() => setHover(star)}
					onMouseLeave={() => setHover(null)}
					style={{
						backgroundColor: "transparent",
						border: "none",
						cursor: "pointer",
					}}
				>
					<FaStar
						size={24}
						color={star <= (hover || rating) ? "gold" : "gray"}
					/>
				</button>
			))}
		</div>
	);
};

export default Rating;
